/**=====================
  4.3 Update CSS Start
==========================**/

// jsgrid css
.jsgrid-filter-row {

    select,
    input {
        padding: 6px 10px;
        border: 1px solid $horizontal-border-color;
        background: $white;
    }
}

// popover
.popover {
    background-color: $white;
    border: none;
    -webkit-box-shadow: 0 0 20px rgba(var(--rgb-theme-default), 0.1);
    box-shadow: 0 0 20px rgba(var(--rgb-theme-default), 0.1);

    .popover-header {
        background-color: $theme-medium-color;
        color: var(--theme-default);
        border-bottom: none;
    }

    .popover-body {
        color: rgba(43, 43, 43, 0.7);
    }
}

.dropdown-menu.show {
    background-color: $white;
    z-index: 2;
}

.toast-container {
    position: unset;
}

.animate-widget {
    .p-25 {
        background-color: $white;
    }
}

.form-control,
.form-select {
    &:focus {
        border-color: var(--theme-default) !important;
        box-shadow: 0 0 0 0.25rem rgba(var(--rgb-theme-default), 0.3) !important;
    }
}

// tooltip css start
.tooltip {
    &.bs-tooltip-top {
        .tooltip-arrow {
            &:before {
                border-top-color: $theme-medium-color;
            }
        }
    }

    &.bs-tooltip-bottom {
        .tooltip-arrow {
            &:before {
                border-bottom-color: $theme-medium-color;
                border-top-color: transparent;
            }
        }
    }

    &.bs-tooltip-start {
        .tooltip-arrow {
            &:before {
                border-left-color: $theme-medium-color;
                border-top-color: transparent;
            }
        }
    }

    &.bs-tooltip-end {
        .tooltip-arrow {
            &:before {
                border-right-color: $theme-medium-color;
                border-top-color: transparent;
            }
        }
    }

    .tooltip-inner {
        background-color: $white;
        color: var(--theme-default);
        box-shadow: $tooltip-shadow;
    }

    .tooltip-arrow {
        &:before {
            border-top-color: $theme-medium-color;
        }
    }
}

// tooltip css end
// dropdown css start
.dropdown-basic {
    .dropdown {
        .dropdown-content {
            a {
                padding: 6px 16px;
                color: $dark-editor-document;
                opacity: 0.6;
                font-size: 13px;
                border-top: 1px solid $light-semi-gray;
                background: $white;

                &:hover {
                    background-color: $white;
                }
            }

            .dropdown-header {
                padding: 8px 16px;
                font-weight: 400;
                color: $dark-color;
                font-size: 13px;
            }
        }
    }

    .dropup {
        .dropup-content {
            top: auto;
        }
    }
}

// dropdown css end
// accordian css start
.default-according {
    .card {
        .card-header {
            padding: 0;
            border: none;
            border-radius: 0;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;

            &.bg-primary,
            &.bg-secondary {
                .btn {
                    border-color: $transparent-color;
                }
            }

            i {
                position: initial;
                font-size: 20px;
                display: inline-block;
                vertical-align: text-bottom;
                margin-right: 5px;

                [dir="rtl"] & {
                    margin-right: unset;
                    margin-left: 5px;
                }
            }

            h5 {
                margin-top: 0;
            }

            .btn-link {
                padding: 12px 18px;
                width: 100%;
                text-align: left;
                font-size: 16px;
                font-weight: 700;
                letter-spacing: 0.7px;
                border-radius: 0;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;

                [dir="rtl"] & {
                    text-align: right;
                }

                @media (max-width: 575px) {
                    padding: 12px 15px;
                }
            }
        }

        .card-body {
            padding: 15px 20px;
            line-height: 22px;
            font-size: 14px;
            border: none;
            border-radius: 0;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            color: $dark-color;
        }
    }
}

.icon-dropdown {
    .dropdown-toggle {
        border: none;
    }
}

.collapse-horizontal {
    .card {
        width: 350px;
        @media (max-width: 820px) {
			width: 210px;
		}
    }
}

// accordian css end
// tab-bootstrap css start
.nav-tabs {
    border-bottom-color: $light-semi-gray;

    .nav-bottom {
        .nav-item {
            .nav-link.active {
                border-color: $light-semi-gray;
            }
        }
    }
}

.nav-tabs {
    .nav-item {
        &.show {
            .nav-link {
                font-weight: 500;
            }
        }
    }

    .nav-link {
        color: $dark-color;

        &.active {
            font-weight: 500;
        }
    }

    &.nav-bottom {
        .nav-item {
            .nav-link {
                &.active {
                    border-color: $light-gray $light-gray #fff;
                }
            }
        }
    }
}

.dropdown-menu {
    -webkit-box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
    box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
    border: none;
    padding: 0;
    z-index: 1;

    .dropdown-item {
        color: #2b2b2b;
        font-size: 13px;
        padding: 6px 12px;
        border-top: 1px solid $light-semi-gray;
        background: $white;

        &:hover {
            background-color: $white;
        }

        &:first-child {
            border-top: none;
        }
    }

    .dropdown-divider {
        margin: 0;
        border-top: 1px solid $gray-60;
    }
}

.blockquote {
    > :last-child {
        margin-top: 0;
    }
}

.modal-content {
    background-color: $white;
}

// tab-bootstrap css end
.border-tab {
    .nav-tabs {
        .nav-item {
            .nav-link {
                &.active {
                    border-bottom: 2px solid var(--theme-default);
                }

                &.show {
                    border-bottom: 2px solid var(--theme-default);
                }

                &:focus {
                    border-bottom: 2px solid var(--theme-default);
                }
            }
        }
    }
}

.timeliny {
    .timeliny-dot::before {
        font-size: $btn-lg-font-size;
    }
}

.component {
    .input-group {
        .btn {
            line-height: 32px;
            text-transform: capitalize;
        }
    }
}

.theme-form {
    .twitter-typeahead {
        .tt-menu {
            width: 100%;

            .tt-dataset {
                width: 100%;
            }
        }
    }

    @media only screen and (max-width: 575px) {
        .custom-dropzone-project {
            .dropzone {
                padding: 20px;
            }
        }
    }
}

.masthead {
    .pl-navs-inline {
        padding-left: 30px !important;

        [dir="rtl"] & {
            padding-left: unset !important;
            padding-right: 30px !important;
        }
    }
}

.login-card {
    .login-main {
        .theme-form {
            label {
                font-size: $body-font-size;
            }

            .show-hide {
                top: 50%;
            }
        }
    }
}

// faq css start
.faq-accordion {
    .card {
        .btn-link {
            svg {
                margin-left: 20px;

                @media (max-width: 575.98px) {
                    margin-left: 10px;
                }
            }
        }
    }
}

// faq css end
.job-filter {
    .faq-form {
        .form-control {
            font-size: 14px;
        }

        .search-icon {
            width: 16px;
            height: 16px;
        }
    }
}

// editor css
.note-btn-group {
    .dropdown-toggle {
        &.show {
            ~.dropdown-menu {
                display: block;
                top: 30px;
            }
        }
    }
}

// owl-carousel css
.carousel {
    .carousel-indicators {
        margin-bottom: 3px;
    }
}

.mouse-wheel {
    .owl-stage-outer {
        .owl-stage {
            width: 5600px !important;

            .owl-item {
                width: 225.333px !important;
            }
        }
    }
}

.carousel-item {
    .carousel-caption {
        width: 100%;
        right: 0;
        left: 0;
        bottom: 0;
        padding: 26px 10px;
        background-color: rgba($dark-card-background, 0.51);
    }

    .carousel-opacity {
        background-color: rgba($light-color, 0.51);
    }
}

.carousel {
    .carousel-control-prev-icon {
        width: calc(23px + (32 - 23) * ((100vw - 320px) / (1920 - 320)));
        height: calc(23px + (32 - 23) * ((100vw - 320px) / (1920 - 320)));
    }

    .carousel-control-next-icon {
        width: calc(23px + (32 - 23) * ((100vw - 320px) / (1920 - 320)));
        height: calc(23px + (32 - 23) * ((100vw - 320px) / (1920 - 320)));
    }
}

// Rating 
.rating-header {
    h4 {
        [dir="rtl"] & {
            direction: ltr;
        }
    }
}

// Animated modal
.animate-img {
    .animate-widget {
        img {
            width: 100%;
            height: 350px;
            margin: 0 auto;
            object-fit: cover;
        }
    }
}

.custom-progress-bar-steps {
    .progress-border-primary.progress {
        direction: ltr !important;
    }
}

.modal-popup {
    &.modal {
        top: 20%;
        left: 50%;
        transform: translate(-50%, 0);

        .modal-dialog {
            .theme-close {
                width: calc(25px + (40 - 25) * ((100vw - 320px) / (1920 - 320)));
                height: calc(25px + (40 - 25) * ((100vw - 320px) / (1920 - 320)));
                font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
            }
        }
    }
}

.animated-modal {
    .form-select {
        @media (max-width:442px) {
            width: 100% !important;
        }
    }
}

.select2-container--default {
    .select2-results__option--highlighted[aria-selected] {
        background-color: var(--theme-default);
        color: $white;
    }
}

.select2-container--default {
    .select2-selection--single {
        .select2-selection__rendered {
            line-height: 17px !important;
        }

        .select2-selection__arrow {
            top: 7px !important;
        }
    }
}

div.dt-button-collection {
    width: 170px;
}

.custom-border-bottom {
    border-bottom: 1px solid $light-gray !important;
}

.my-gallery.gallery-with-description {
    .pswp--zoom-allowed {
        .pswp__img {
            border: none !important;
        }
    }
}

.my-gallery.gallery-with-description {
    .pswp__caption__center {
        h4 {
            color: $white;
        }
    }
}

.ecommerce-widget {
    .support-ticket-font {
        background-color: unset;
    }
}

// TO-DO scss
.task-container {
    .badge {
        line-height: 13px;
    }
}

// starter kit
.starter-main {
    .alert-primary {
        &.inverse {
            padding: 13px 20px 13px 65px;
            border-color: var(--theme-default);
            color: $dark-color;

            [dir="rtl"] & {
                padding: 13px 65px 13px 20px;
            }

            &:before {
                content: "";
                position: absolute;
                left: 54px;
                width: 0;
                height: 0;
                border-left: 7px solid var(--theme-default);
                border-top: 7px solid transparent;
                border-bottom: 7px solid transparent;

                [dir="rtl"] & {
                    border-right: 7px solid var(--theme-default);
                    border-left: unset;
                }
            }

            i {
                padding: 17px 20px;
                display: flex;
                align-items: center;
                background-color: var(--theme-default);
                color: $white;
                border-radius: 3px 0 0 3px;
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;

                [dir="rtl"] & {
                    right: 0;
                    left: unset;
                }
            }
        }
    }
}
.custom-dropzone-project {
    .custom-drop-zone {
        .dropzone {
            .dz-message {
                padding-top: 15px;
                background-color: unset;
                border: none;
                margin: 0!important;
                min-height: 77px;
                overflow: hidden;
                .dz-text {
                    position: unset;
                    top: 0;
                    width: unset;
                    padding: 0;
                    text-align: center;
                    transform: unset;
                }
            }
            .dz-preview {
                &.dz-image-preview {
                    background-color: unset;
                }
            }
        }
    }
}
.custom-drop-zone {
    .dropzone {
        background: unset !important;   
        min-height: 148px !important;
        border-radius: 10px !important;
        border: 2px dashed var(--theme-default) !important;
        background-color: rgba(var(--rgb-theme-default), 0.07) !important;
        overflow: hidden !important;
        i {
            font-size: 50px;
            color: var(--theme-default);
        }
    }
}

///////////

@keyframes tada {
    0% {
      transform: scaleX(1);
    }
    10% {
      transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
    }
    20% {
      transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
    }
    30% {
      transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
    }
    40% {
      transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
    }
    50% {
      transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
    }
    60% {
      transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
    }
    70% {
      transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
    }
    80% {
      transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
    }
    90% {
      transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
    }
    100% {
      transform: scaleX(1);
    }
}

// New Css.

.angular-editor-button {
    &.active {
        background: var(--theme-default) !important;
        color: $white;
    }
}
.custom-dropzone {
    background-color: transparent !important;
}
.swal2-confirm {
    background: var(--theme-default) !important;
    box-shadow: unset !important;
}
.cal-month-view {
    .cal-day-badge {
        background-color: var(--theme-default);
    }
    .cal-event {
    background-color: var(--theme-default) !important;
    }
    .cal-day-cell{
        &.cal-today {
            background-color: transparent;
        }
    }
    .cal-open-day-events {
        background-color: $body-color;
        box-shadow: unset;
    }
}
.cal-event-title {
    color: var(--theme-default);
}
.faq-accordion {
    &.default-according {
        .accordion {
            .accordion-item {
                border: unset;
            }
        }
    }
}
ngb-datepicker-navigation-select {
    >.form-select {
        margin-left: 4px;
    }
}
.ngb-dp-weekdays{
    border-bottom: none !important;
    background-color: var(--white) !important;
}
.ngb-dp-weekday {
    color: var(--theme-default) !important;
}
.digits {
    .calendar {
        z-index: 1;
    }
}
.btn-close {
    &:focus {
        box-shadow: unset;
    }
}

.custom-dropzone {
    .dz-single {
        z-index: 1;
        .dz-message {
            width: 100% !important;
            height: 100px !important;
            background-color: transparent !important;
            border: transparent !important;
            i {
                font-size: 20px;
            }
        }
    }
    .dz-multiple {
        z-index: 1;
        .dz-message {
            width: 100% !important;
            height: 100px !important;
            background-color: transparent !important;
            border: transparent !important;
            i {
                font-size: 20px;
            }
        }
    }
}
.dropzone {
    .dz-preview {
        .dz-remove {
            position: absolute !important;
            z-index: 9 !important;
        }
    }
}

.custom-dropzone-project {

    .dropzone {
        min-height: unset;
        border: unset;
        background: unset;
        padding: unset;
    }
    .custom-drop-zone {
        .dropzone {
            .dz-message {
                padding-top: 15px;
                background-color: unset;
                border: none;
                margin: 0!important;
                overflow: hidden;
                .dz-text {
                    position: unset;
                    top: 0;
                    width: unset;
                    padding: 0;
                    text-align: center;
                    transform: unset;
                }
            }
            .dz-preview {
                &.dz-image-preview {
                    background-color: unset;
                }
            }
        }
    }
}


.custom-drop-zone {
    .dropzone {
        background: unset !important;   
        min-height: 148px !important;
        border-radius: 10px !important;
        border: 2px dashed var(--theme-default) !important;
        background-color: rgba(var(--rgb-theme-default), 0.07) !important;
        overflow: hidden !important;
        i {
            font-size: 50px;
            color: var(--theme-default);
        }
        .dz-message {
            background-color: unset !important;
            border: unset !important;
            position: unset !important;
            margin: unset !important;
        }
    }
    &.custom-dropzone-1 {
        .dz-image {
            height: 120px !important;
            width: 120px !important;
            margin:  0 auto; 
        }
        .dz-preview {
            width: unset !important;
            &:hover {
                width: unset !important;
            }
        }
    }
}

.dropzone {
    &.dz-started {
        .dz-message {
            display: none !important;
        }
    }
    &.dz-wrapper {
        padding: 25px !important;
    }
}
#carouselExampleControls{
    .owl-prev{
      position: absolute;
      top: 50%;
      left: 20px;
    }
    .owl-next{
      position: absolute;
      top: 50%;
      right: 20px;
    }
}
.owl-theme .owl-nav [class*=owl-]{
    background-color: transparent !important;
    font-size: 20px;
}
.img-cropper {
    .btn-showcase {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .btn {
        margin-bottom: 10px;
        margin-right: 10px;
        [dir="rtl"] & {
          margin-right: unset;
          margin-left: 10px;
        }
      }
      .image-cropper-btn {
        width: 100%;
        input {
          width: 100%;
          height: 150px;
          margin-bottom: 20px;
          padding-left: calc(50% - 90px);
          padding-top: 60px;
          [dir="rtl"] & {
            padding-right: calc(50% - 90px);
            padding-left: unset;
          }
        }
      }
    }
}
.NgxEditor__Dropdown--Selected {
    color: var(--theme-default) !important;
    background-color: rgba(var(--rgb-theme-default), 0.1) !important;
}
.NgxEditor__MenuItem--Active {
    color: var(--theme-default) !important;    
    background-color: rgba(var(--rgb-theme-default), 0.1) !important;
}
.dark-card {
    .card-body {
        border-radius: 0 !important;
    }
    .card-header {
        border-bottom: 1px solid $dark-card-border !important;
    }
    .card-footer {
       border-top: 1px solid $dark-card-border !important;
    }
    .bg-dark {
        background-color: $dark-card-background !important;
    }
}
.ux-Designer {
    &.card-body {
        border-radius: 0;
    }
}
.chart-block canvas{
    height: 355px!important;
    width: auto!important;
    margin: 0 auto;
}
.support-table {
    div.dataTables_wrapper {
        table {
            &.dataTable {
                tbody {
                    > tr {
                        > td {
                            border-top: 1px solid $light-color;
                        }
                    }
                }
            }
        }
    }
}
/**=====================
    4.3 Update CSS Ends
==========================**/