@import "ngx-toastr/toastr";
@import "ngx-bootstrap/datepicker/bs-datepicker";

.bs-datepicker {
	border-radius: 10px;
	.bs-datepicker-container {
		padding: 0;
		.bs-datepicker-head {
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;
		}
	}
}

.toast-right-top,
.toast-left-top,
.toast-right-bottom,
.toast-left-bottom {
	height: auto;
}
.toast-container {
	padding: 10px;
	max-width: 100%;
	&.toast-top-right {
		top: 0;
		right: 0;
	}
	&.toast-top-left {
		top: 0;
		left: 0;
	}
	&.toast-bottom-right {
		bottom: 0;
		right: 0;
	}
	&.toast-bottom-left {
		bottom: 0;
		left: 0;
	}
	div[custom-toast-component] {
		background: white !important;
		border-radius: 0.5rem;
	}
	div[custom-toast-component],
	.ngx-toastr {
		border: 0;
		max-width: 100%;
		background: transparent;
		box-shadow: 0 3px 5px rgba(100, 100, 100, 0.5);
		margin: 0 0 0.5rem;
		padding: 0;
		overflow: auto;
	
		&.toast-error {
			color: #4a5568;
			.toaster {
				background-color: rgba($danger-color, 0.15);
				.toast-icon {
					background-color: $danger-color;
				}
			}
			.close-icon {
				color: $danger-color;
			}
		}
	
		&.toast-success {
			color: #4a5568;
			.toaster {
				background-color: rgba($success-color, 0.15);
				.toast-icon {
					background-color: $success-color;
				}
			}
			.close-icon {
				color: $success-color;
			}
		}
	
		&.toast-info {
			color: #4a5568;
			.toaster {
				background-color: rgba($info-color, 0.15);
				.toast-icon {
					background-color: $info-color;
				}
			}
			.close-icon {
				color: $info-color;
			}
		}
	
		&.toast-warning {
			color: #4a5568;
			.toaster {
				background-color: rgba($warning-color, 0.15);
				.toast-icon {
					background-color: $warning-color;
				}
			}
			.close-icon {
				color: $warning-color;
			}
		}
	
		.toaster {
			display: grid;
			grid-template-columns: minmax(0, 50px) minmax(0, 1fr) minmax(0, 25px);
			padding-right: 0.5rem;
			color: #ffffff;
			background-color: #333333;
			width: 340px;
			max-width: 100%;
			min-height: 80px;
			border-radius: 0.5rem;
			box-shadow: none;
			margin: 0;
			overflow: hidden;
	
			.toast-icon {
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 26px;
				grid-row: span 2;
			}
	
			.toast-details {
				font-size: 1rem;
				padding: 1rem;
				text-align: left;
			}
	
			.close-icon {
				align-self: center;
				a {
					color: currentColor;
					position: relative;
					top: unset;
					bottom: unset;
					left: unset;
					right: unset;
					display: block;
					margin: 0;
					width: 100%;
					text-align: center;
					float: none;
				}
			}
	
			.toast-details {
				color: #4a5568;
				.toast-title {
					font-size: 1rem;
					font-weight: 600;
				}
				.toast-message {
					font-size: 0.875rem;
				}
			}
	
			.progress-content {
				grid-column: 2 / 3;
				position: relative;
				display: block;
				width: 100%;
			}
		}
	}
}

.swal2-container {
    .swal2-popup {
		.swal2-icon {
			.swal2-icon-content {
				font-size: 2.75em;
			}
		}
		.swal2-title {
			font-size: 22px;
		}
		.swal2-html-container {
			font-size: 16px;
			p {
				margin: 0 0 5px;
				line-height: normal;
				font-size: inherit;
			}
		}
		.swal2-actions {
			.swal2-cancel {
				-webkit-box-ordinal-group: 1;
				-ms-flex-order: 0;
				order: 0;
			}
			.swal2-confirm {
				background-color: var(--primary-color);
				-webkit-box-ordinal-group: 2;
				-ms-flex-order: 1;
				order: 1;
				&:focus {
					box-shadow: 0 0 0 3px rgba(var(--primary-color), 0.5);
				}
			}
			.swal2-styled {
				font-size: 16px;
			}
		}
    }
}

.sidebar-wrapper {
    .sidebar-toggle {
        &.responsive__mobile {
            display: none;
        }
    }
}
.bg__close-responsive__mobile {
    display: none;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.071);
    z-index: 8;
    transition: all ease-in-out 0.3s;
}

.login-card.login-bg-primary {
    background: var(--primary-color);
}
.loading_wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999999999999999;
	.backdrop_loading {
		background-color: rgba(0, 0, 0, 0.3);
		z-index: 1;
		pointer-events: none;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.loading__content {
		display: block;
		width: auto;
		z-index: 2;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background: #fff;
		padding: 1.5rem 2rem;
		border-radius: 15px;
		text-align: center;
		p {
			display: block;
			margin: 0;
			text-align: center;
			width: 100%;
			font-weight: 500;
			font-size: 16px;
		}
	}
}

// Loading Default
.loads-ellipsis {
	margin: 0 auto;
	display: block;
	position: relative;
	width: 80px;
	height: 80px;
	div {
		position: absolute;
		top: 33px;
		width: 13px;
		height: 13px;
		border-radius: 50%;
		background: var(--primary-color);
		animation-timing-function: cubic-bezier(0, 1, 1, 0);
		&:nth-child(1) {
			left: 8px;
			animation: lds-ellipsis1 0.6s infinite;
		}
		&:nth-child(2) {
			left: 8px;
			animation: lds-ellipsis2 0.6s infinite;
		}
		&:nth-child(3) {
			left: 32px;
			animation: lds-ellipsis2 0.6s infinite;
		}
		&:nth-child(4) {
			left: 56px;
			animation: lds-ellipsis3 0.6s infinite;
		}
	}
}
@keyframes lds-ellipsis1 {
	0% {
		transform: scale(0);
	}
	100% {
		transform: scale(1);
	}
}
@keyframes lds-ellipsis3 {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(0);
	}
}
@keyframes lds-ellipsis2 {
	0% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(24px, 0);
	}
}

// Loading 2
.loadingio-spinner-dual-ball {
	width: 100px;
	height: 100px;
	display: inline-block;
	overflow: hidden;
	background: transparent;
}
.ldio-dualball div {
	position: absolute;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	top: 30px;
	left: 10px;
}
.ldio-dualball div:nth-child(1) {
	background: var(--primary-color);
	animation: ldio-dualball 1s linear infinite;
	animation-delay: -0.5s;
}
.ldio-dualball div:nth-child(2) {
	background: var(--secondary-color);
	animation: ldio-dualball 1s linear infinite;
	animation-delay: 0s;
}
.ldio-dualball div:nth-child(3) {
	background: var(--primary-color);
	animation: ldio-dualball-o 1s linear infinite;
	animation-delay: -0.5s;
}
.ldio-dualball {
	width: 100%;
	height: 100%;
	position: relative;
	transform: translateZ(0) scale(1);
	backface-visibility: hidden;
	transform-origin: 0 0;
}
.ldio-dualball div {
	box-sizing: content-box;
}
@keyframes ldio-dualball-o {
	0% {
		opacity: 1;
		transform: translate(0 0);
	}
	49.99% {
		opacity: 1;
		transform: translate(40px, 0);
	}
	50% {
		opacity: 0;
		transform: translate(40px, 0);
	}
	100% {
		opacity: 0;
		transform: translate(0, 0);
	}
}
@keyframes ldio-dualball {
	0% {
		transform: translate(0, 0);
	}
	50% {
		transform: translate(40px, 0);
	}
	100% {
		transform: translate(0, 0);
	}
}

// Loading 3
.loading-roller {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
	div {
		animation: loading_roaller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		transform-origin: 40px 40px;
		&:after {
			content: " ";
			display: block;
			position: absolute;
			width: 7px;
			height: 7px;
			border-radius: 50%;
			background: var(--tertiary-color);
			margin: -4px 0 0 -4px;
		}
		&:nth-child(1) {
			animation-delay: -0.036s;
			&:after {
				top: 63px;
				left: 63px;
			}
		}
		&:nth-child(2) {
			animation-delay: -0.072s;
			&:after {
				top: 68px;
				left: 56px;
			}
		}
		&:nth-child(3) {
			animation-delay: -0.108s;
			&:after {
				top: 71px;
				left: 48px;
			}
		}
		&:nth-child(4) {
			animation-delay: -0.144s;
			&:after {
				top: 72px;
				left: 40px;
			}
		}
		&:nth-child(5) {
			animation-delay: -0.18s;
			&:after {
				top: 71px;
				left: 32px;
			}
		}
		&:nth-child(6) {
			animation-delay: -0.216s;
			&:after {
				top: 68px;
				left: 24px;
			}
		}
		&:nth-child(7) {
			animation-delay: -0.252s;
			&:after {
				top: 63px;
				left: 17px;
			}
		}
		&:nth-child(8) {
			animation-delay: -0.288s;
			&:after {
				top: 56px;
				left: 12px;
			}
		}
	}
}
@keyframes loading_roaller {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}


.content__field {
	position: relative;
	&.content__field-icon-status {
		&.cy__was-validated {
			.form-control {
				padding-right: calc(1.5em + 0.75rem);
				background-repeat: no-repeat;
				background-position: right calc(0.375em + 0.1875rem) center;
				background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
			}
			&:has(.show-hide) .form-control {
				padding-right: calc(1.5em + 2.75rem);
			}
			.show-hide {
				right: 35px;
			}
		}
		// &.cy__was-validated .form-control:valid, /*Is in html normal code*/
		// &.cy__was-validated .form-select:valid, /*Is in html normal code*/
		&.cy__was-validated .form-control.ng-valid, /*Is in angular code*/
		&.cy__was-validated .form-select.ng-valid /*Is in angular code*/ {
			border-color: var(--success-color);
			background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
		}
		// &.cy__was-validated .form-control:invalid, /*Is in html normal code*/
		// &.cy__was-validated .form-select:invalid /*Is in html normal code*/
		&.cy__was-validated .form-control.ng-invalid, /*Is in angular code*/
		&.cy__was-validated .form-select.ng-invalid /*Is in angular code*/ {
			border-color: var(--danger-color);
			background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
		}
	}
	.show-hide {
		top: 50%;
		transform: translateY(-50%);
	}
	.input__image, .input__file {
		width: auto;
		display: block;
		.content__file-input .btn__image-change {
			width: auto;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			gap: 10px;
			border: 1px solid #d1d5d8;
			border-radius: 8px;
			min-height: 48px;
			line-height: normal;
			font-size: 16px;
			padding: 12px 15px;
			margin: 0;
			cursor: pointer;
			span {
				word-break: break-word;
				text-align: left;
			}
		}
		.input__image-content {
			// padding: 10px;
			width: 100%;
			display: block;
			.input__image-preview {
				width: 100%;
				display: block;
				border-radius: 10px;
				&:has(img) {
					padding: 10px;
					border: 2px dashed #ddd;
					margin-bottom: 10px;
				}
				img {
					display: block;
					width: auto;
					max-width: 100%;
					margin: 0 auto;
					max-height: 300px;
				}
			}
		}
		input {
			display: none;
		}
	}
}

.sidebar__content-logo {
	display: block;
    max-width: calc(100% - (10px + 36px));
}

.menu__link.active {
	transition: all 0.5s ease;
    position: relative;
    border-radius: 10px;
    background-color: rgba(var(--rgb-primary-color, (7, 11, 37)), 0.1);
	margin-bottom: 0 !important;
	span {
		color: var(--theme-default);
		transition: all 0.3s ease;
		font-weight: 600;
	}
}

.btn {
    display: inline-flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	gap: 0.2rem;
	&.btn-icon {
		padding: 8px 15px;
	}
	app-feather-icons svg {
		vertical-align: middle;
		width: 1.2em;
		height: 1.2em;
	}
}

.btn-group-space {
	display: inline-flex;
    gap: 0.5rem;
}

.text-left {
	text-align: left;
}
.text-right {
	text-align: right;
}
.text-center {
	text-align: center;
}
.text-justify {
	text-align: justify;
}

.cy__paginator {
	.cy__paginator-total {
		font-size: 16px;
	}
	.pagination .page-link {
		app-feather-icons svg {
			vertical-align: middle;
		}
	}
}

.user__edit-image {
	position: relative;
	height: auto;
	img {
		width: 100px;
		height: 100px;
		max-width: 155px;
		max-height: 155px;
		border-radius: 50%;
		border: 7px solid #fff;
		box-shadow: 0 0 6px 3px #4466f21a;
	}
	.icon-wrapper {
		position: absolute;
		bottom: -5px;
		left: 60%;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 40px;
		width: 40px;
		border-radius: 50%;
		background-color: #fff;
		cursor: pointer;
		overflow: hidden;
		margin: 0 auto;
		font-size: 17px;
		box-shadow: 0 0 6px 3px #4466f21a;
		input {
			display: none;
		}
	}
}

.card{
	padding: 20px;
}

.btnBotton{
	display: flex;
	align-items: end;
	justify-content: flex-start;
	height: 100%;
}

.layout-center-col{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 16px;
}