.br-movie {
  --br-active-color: #4278F5;
  --br-gap: 2px;
  --br-width: 60px;
  --br-height: 8px;

  .br-units {
    margin: 15px 0;
  }

  .br-unit-inner {
    display: block;
    width: var(--br-width);
    height: var(--br-height);
    float: left;
    background-color: var(--br-active-color);
    opacity: 0.25;

    &.br-active {
      opacity: 1;
    }
  }

  &.br-readonly {
    .br-active {
      opacity: 0.1;
    }
  }

  .br-text {
    letter-spacing: 2px;
    font-size: 16px;
    clear: both;
    text-align: center;
    font-weight: 600;
    display: block;
    color: var(--br-active-color);
  }
}
