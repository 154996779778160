.br-stars {
  --br-gap: 5px;
  --br-font-size: 28px;
  --br-width: var(--br-font-size);
  --br-height: var(--br-font-size);

  .br-unit-inner {
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='iso-8859-1'?%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 512.002 512.002' style='enable-background:new 0 0 512.002 512.002;' xml:space='preserve'%3E%3Cpath style='fill:%23F0D355;' d='M400.07,502.64c-3.551,0-7.116-0.825-10.398-2.5L256.001,431.95L122.33,500.14 c-3.283,1.675-6.848,2.5-10.399,2.5c-4.728,0-9.431-1.464-13.408-4.336c-6.961-5.031-10.52-13.537-9.215-22.026l22.995-149.648 L6.579,219.236c-6.002-6.096-8.103-15.031-5.446-23.163c2.656-8.131,9.626-14.103,18.069-15.481l147.912-24.138l68.49-134.585 c3.904-7.675,11.786-12.507,20.397-12.507c8.611,0,16.493,4.833,20.396,12.507l68.49,134.585L492.8,180.592 c8.444,1.378,15.413,7.35,18.07,15.481c2.656,8.132,0.555,17.067-5.447,23.163L399.7,326.628l22.993,149.648 c1.305,8.49-2.254,16.995-9.215,22.026C409.502,501.175,404.798,502.64,400.07,502.64z'/%3E%3Cg style='opacity:0.1;'%3E%3Cpath style='fill:%23414042;' d='M146.585,486.578c-6.96-5.03-10.52-13.537-9.215-22.026l22.995-149.647L54.643,207.512 c-6.003-6.096-8.103-15.031-5.447-23.163c1.232-3.769,3.397-7.065,6.199-9.663l-36.193,5.906 c-8.444,1.378-15.413,7.35-18.069,15.481c-2.657,8.132-0.556,17.067,5.446,23.163l105.724,107.391L89.307,476.276 c-1.305,8.49,2.254,16.995,9.215,22.026c3.977,2.872,8.68,4.336,13.408,4.336c3.551,0,7.117-0.826,10.399-2.5l25.267-12.89 C147.257,487.03,146.914,486.817,146.585,486.578z'/%3E%3C/g%3E%3C/svg%3E");
    width: var(--br-width);
    height: var(--br-height);
    filter: grayscale(1);

    &.br-active {
      filter: grayscale(0);
    }
  }
}

