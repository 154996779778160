/**=====================
   2.20 List CSS Start
==========================**/
.list-group-item.active {
	background-color: var(--theme-default);
	border-color: var(--theme-default);
}
.list-group {
	i {
		margin-right: $list-group-margin;
	}
	img {
		height: 40px;
		width: 40px;
		margin-right: $list-group-margin;
	}
}
$list-name: primary,
	secondary,
	success,
	warning,
	info,
	light,
	danger,
	dark;
$list-light-color: $light-primary,
	$light-secondary,
	$light-success,
	$light-warning,
	$light-info,
	$light-white,
	$light-danger,
	$light-dark;
@each $var in $list-name {
	$i: index($list-name, $var);
	.list-light-#{$var} {
		background-color: nth($list-light-color, $i);
		&:hover,
		&:active,
		&:focus {
			background-color: nth($list-light-color, $i);
			color: $dark-color;
		}
	}
	.list-hover-#{$var} {
		background-color: unset;
		&:hover,
		&:active,
		&.active,
		&:focus {
		background-color: nth($list-light-color, $i);
			color: $dark-color;
			z-index: 0;
		}
	}
}
.horizontal-list-wrapper {
	.list-group {
		.list-group-item {
			padding: 8px;
		}
	}
}
.list-wrapper {
	display: flex;
	align-items: center;
	gap: 12px;
	flex-wrap: wrap;
	@media (max-width: 414px) {
		flex-wrap: unset;
		word-break: break-word;
	}
	@media (max-width: 366px) {
		padding-bottom: 8px;
	}
	.list-img {
		width: calc(40px + (55 - 40) * ((100vw - 320px) / (1920 - 320)));
		height: calc(40px + (55 - 40) * ((100vw - 320px) / (1920 - 320)));
		margin-bottom: 10px;
		border-radius: 50%;
	}
	.list-content {
		p {
			margin-bottom: 0;
		}
	}
}
.scrollbar-wrapper {
	max-height: 270px;
	overflow: auto;
}
.main-lists-content {
	small {
		@media (max-width: 480px) {
			display: none;
		}
	}
}
.list-behavior-1 {
	@media (max-width: 1199px) {
		gap: 12px;
	}
	@media (max-width:575px) {
		&:first-child {
			margin-bottom: 14px;
		}
	}
	img {
		@media (max-width:1999px) {
			height: 86px;
		}
		@media (max-width:992px) {
			height: 70px;
		}
	}
	.flex-grow-1 {
		margin-left: 16px;
		@media (max-width:1199px) {
			margin-left: 0;
			p {
				display: block;
				display: -webkit-box;
				max-width: 100%;
				margin: 0 auto;
				-webkit-line-clamp: 3;
				-webkit-box-orient: vertical;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
		[dir="rtl"] & {
			margin-right: 16px;
			margin-left: unset;
		}
	}
}
.list-behaviors {
	>div:nth-child(even) {
		.flex-grow-1 {
			order: -1;
			margin-left: unset;
			margin-right: 16px;
			@media (max-width:1199px) {
				margin-left: 0;
				margin-right: 0;
				order: unset;
			}
		}
	}
}
.list-group {
	.list-group-item {
		padding: 11px 16px;
		.form-check-input {
			&:focus {
				box-shadow: unset !important;
			}
		}
		i {
			[dir="rtl"] & {
				margin-left: 10px;
				margin-right: unset;
			}
		}
	}
}
.contact-profile {
	@media (max-width:1216px) {
		background-color: unset;
		&.list-light-dark {
			&:hover {
				background-color: unset;
			}
		}
	}
}
.badge-list {
	.list-group-item {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		flex-wrap: wrap;
		div {
			margin-right: auto;
			margin-left: 8px;
		}
	}
}
.Contextual {
	.list-group {
		.list-group-item {
			border-top-width: 0 !important;
			border-radius: 0;
		}
		&:first-child {
			.list-group-item {
				border-top-width: 1px !important;
			}
		}
	}
}
/**=====================
   2.20 List CSS Ends
==========================**/