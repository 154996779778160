/**=====================
  38. Landing CSS Start
==========================**/
@media (max-width: 1799px) {
  .landing-page {
    .landing-home {
      .home-bg {
        .home-text {
          h2 {
            .line-text {
              left: 1%; 
            }
          }
        }
        .home-screen { 
          .charts-card {
            .dashboard-card {
              li {
                &:last-child {
                  top: 12%;
                  right: 46px;
                }
                &:nth-child(2){
                  left: 211px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width:1699px) {
  .landing-page {
    .landing-header{
      padding: 18px 70px; 
    }

    .landing-home {
      .home-bg {
        .home-screen {
          .charts-card {
            .dashboard-card {
              li {
                &:last-child {
                  top: 13%;
                  right: 89px;
                  width: 178px;
                }
                &:nth-child(2){
                  left: 199px;
                }
                &:first-child{
                  width: 28%;
                }
              }
            }
          }
        }
        .home-text{
          h2{
            // .line-text{ 
            //   width: 150px;
            // }
          } 
        }
      }
      .wave-vibe{
        left: -12%; 
      }
    }
  }
}
@media (max-width:1599px) {
  .landing-page {
    .landing-home {
      .home-bg {
        .home-screen {
          top: -228px;
          .charts-card {
            .dashboard-card {
              li {
                &:first-child{
                  bottom: 23%;
                  left: -61px;
                  width: 202px;
                }
                &:nth-child(2){
                  left: 187px;
                }
                &:last-child {
                  right: 99px;
                  top: 14%;
                  width: 149px;
                }
              }
            }
          }
        }
        .home-text{
          h2{
            // .line-text{
            //   width: 132px;
            // }
          } 
        } 
      }
      .wave-vibe{
        left: -21%; 
      }
    }
  }
}
@media (max-width:1499px) {
  .landing-page {
    .landing-home {
      .home-bg {
        padding: 70px 0 215px;
        .home-text {
          p{
            &.description-name{ 
              font-size: 16px;
            }   
          }
          .main-title {
            .project-name{ 
              font-size: 18px;
            }
          }
          // h2 {
          //   .line-text {
          //     width: 117px;
          //   }
          // }
        }
        .home-screen {
          .charts-card {
            .dashboard-card {
              li {
                &:first-child{
                  bottom: 22%;
                  left: -59px;
                  width: 187px;
                }
                &:nth-child(2){
                  left: 156px;
                }
                &:last-child{
                  right: 96px;
                  width: 141px; 
                }
              }
            }
          }
        }
      }
      .wave-vibe{
        left: -28%;
      }

    }
  }
}
@media(max-width: 1399px) {
  .landing-page {
    .landing-header{
      padding: 18px 20px;
    }
    .landing-home {
      .home-bg {
        padding: 70px 0 160px;
        .home-screen {
          .charts-card {
            .dashboard-card {
              li {
                &:first-child{
                  bottom: 25%;
                  left: -42px;
                  width: 152px;             
                }
                &:nth-child(2){
                  left: 120px;
                }
                &:last-child{
                  right: 94px; 
                  width: 129px;
                }
              }
            }
          }
        }
      }
      .wave-vibe{
        left: -36%;

      }
      .home-text {
				h2 {
					.line-text {
						left: 1%;
					}
				}
			}
    }
  }
}
@media (max-width: 1299px) {
  .landing-page{
    .landing-home{
      .home-bg{
        .home-screen{
          top: -198px;
          .charts-card {
            .dashboard-card {
              li{
                &:first-child{
                  bottom: 23%;
                  left: -42px;
                  width: 152px;
                }
                &:last-child{
                  right: 99px;
                  width: 114px;
                }
              }
            }
          }
        }
        .home-text {
          .main-title {
            .project-name{
              font-size: 16px;
            }
          }
        }
      }  
      .wave-vibe{ 
        left: -45%;
      }
    }
  }
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1366px) {
  .landing-page {
    .demo-section {
      .demo-box {
        padding: 15px;
        .demo-title {
          padding-top: 10px;
          h3 {
            font-size: 18px;
          }
        }
      }
    }
  }
}
@media (max-width:1199px) {
  .landing-page {
    // .big-title{
    //   font-size: 60px;
    // }
    .landing-header{ 
      padding: 15px 30px;
      ul.landing-menu {
        position: fixed;
        top: 0;
        right: -320px;
        width: 300px;
        height: 100vh;
        background-color: $white;
        display: block;
        transition: all 0.5s ease;
        &.open {
          right: 0;
        }
        li.nav-item {
          display: block;
          background-color: $white;
          text-align: left;
          &.menu-back {
            display: block;
          }
          a.nav-link{
            color: $theme-body-font-color;
            padding: 10px 20px;
          }
          &:hover{
            a.nav-link{
              color: var(--theme-default);
            }
          }
        }
      }
      .buy-block {
        .toggle-menu {
          display: block;
        }
      }
    }
    .landing-home {
      .home-bg {
        .home-screen {
          .charts-card {
            .dashboard-card {
              li {
                &:first-child{
                  bottom: 22%;
                  left: -42px;
                  width: 134px;
                }
                &:last-child{
                  right: 103px;
                  width: 101px;
                }
              }
            }
          }
        }
      }
      .wave-vibe{
        left: -62%;
      }
    }

  }
}
@media (max-width:1099px) {
  .landing-page {
    .landing-home {
      .wave-vibe{
        left: -74%;
      }
      .home-bg {
        .home-screen {
          .charts-card {
            .dashboard-card {
              li {
                &:first-child{
                  bottom: 22%;
                  left: -42px;
                  width: 134px;
                }
                &:last-child {
                  right: 97px;
                  width: 93px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media(max-width:991px){
  .landing-page {
    .landing-home {
      .home-bg {
        .home-screen {
          top: -153px;
          .charts-card {
            .dashboard-card {
              li {
                &:first-child{
                  bottom: 22%;
                  left: -38px;
                  width: 111px;
                }
                &:nth-child(2){
                  left: 99px; 
                  top: 27%;
                }
                &:last-child {
                  right: 25px;
                  width: 86px;
                }
              }
            }
          }
        }
        .home-text{
          p{
            &.description-name{
              font-size: 14px;
            }
          }
        } 
      }
      .wave-vibe{
        left: -84%;
      }
    }
    .demo-section{
      .demo-block{
        padding: 0 18px;
      }
    }
    .section-py-space {
      padding-top: 50px;
      padding-bottom: 50px;
    }
    .section-pt-space {
      padding-top: 50px;
    }
    .section-pb-space {
      padding-bottom: 50px;
    }
    //landing header//
    .btn-landing {
      padding: 10px 25px;
    }
    // landing-home
    .landing-home {
      position: relative;
      z-index: 1;
      .landing-home-contain {
        text-align: center;
        width: 80%;
        margin: auto;
        padding-top: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
        p{
          width:90%;
        }
        .btn-landing {
          margin-right: auto;
          margin-left: auto;
        }
      }
    }
    // framework
    .framework {
      .nav {
        .nav-link {
          img {
            height: 35px;
            width: auto;
          }
        }
        .nav-item {
          &:nth-child(n+2) {
            margin-left: 10px;
          }
        }
      }
    }
    // counter-sec
    .counter-sec {
      .counter-block {
        margin-bottom: -30px;
        >div {
          margin-bottom: 30px;
        }
      }
      .counter-box {
        padding: 35px 25px;
      }
    }
    // footer
    .landing-footer {
      .footer-contain {
        img {
          height: 100px;
        }
        .star-rate {
          margin-bottom: 40px;
        }
      }
    }
  }
}
@media(max-width: 899px){
  .landing-page {
    .landing-home{
      .wave-vibe{
        left: -91%;
      }
      .home-bg {
        .home-screen {
          top: -143px;
          .charts-card {
            .dashboard-card {
              li {
                &:first-child{
                  bottom: 21%;
                  left: -40px;
                  width: 103px;
                }
                &:nth-child(2){
                  left: 82px;
                  top: 27%;
                }
                &:last-child {
                  right: 25px;
                  width: 75px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media(max-widthL: 799px){
  .landing-page {
    .landing-home {
      .home-bg {
        .home-screen{
          top: -58px;
          .charts-card{
            .dashboard-card{
              li{ 
                &:last-child{ 
                  right: 28px;
                  width: 65px;
                }
                &:nth-child(2){
                  left: 75px;
                  top: 27%;
                }
              }
            }
          }
        }
        padding: 70px 0 144px; 
      }
      .home-text {
        h2 {
          .line-text{
            width: 81px;
            top: 57%; 
          }
        }
      }
    }
  }
}

@media(max-width:767px) {
  .landing-page {
    .title {
      h2 {
        font-size: 24px;
      }
    }
  
    .landing-home{
      .home-bg {
        .home-screen{
          top: -85px;
        }
      }
      .landing-card {
        display: none;
      }
      .landing-progress {
        display: none;
      }
      .landing-table {
        display: none;
      }
      .landing-chart {
        display: none;
      }
    }
    .section-py-space {
      padding-top: 40px;
      padding-bottom: 40px;
    }
    .section-pt-space {
      padding-top: 40px;
    }
    .section-pb-space {
      padding-bottom: 40px;
    }
    // landing home 
    .landing-home {
      margin-bottom: 0;
      .landing-home-contain {
        height: 520px;
        padding-top: 60px;
        padding-bottom: 0;
        h6 {
          margin-bottom: 10px;
        }       
        p {
          width: auto;
          margin-bottom: 25px;
        }
      }  
    }
    //demo section
    .demo-section {
      .demo-block {
        margin-bottom: -20px;
        >div {
          margin-bottom: 20px;
        }
      }
      .demo-box {
        width: fit-content;
        margin: auto;
        .img-wrraper {
          ul.demo-link {
            li {
              &:nth-child(n+2) {
                margin-left: 5px;
              }
              a {
                font-size: 10px;
              }
            }
          }
        }
        .demo-title {
          h3 {
            font-size: 18px;
          }
        }
      }
    }
    // counter sec
    .counter-sec {
      .counter-box {
        .count-number {
          width: 50px;
          height: 50px;
        }
        .count-detail {
          h4 {
            font-size: 20px;
          }
        }
      }
    }
    // core feature
    .core-feature {
      .feature-box {
        .icon-wrraper {
          width: 50px;
          height: 50px;
          margin-bottom: 10px;
        }
        h4 {
          font-size: 20px;
        }
      }
    }
    // framework
    .framework {
      .nav {
        .nav-link {
          img {
            height: 35px;
          }
          h5 {
            font-size: 16px;
          }
          &.active {
            &:before {
              border-left: 20px solid transparent;
              border-right: 20px solid transparent;
              border-top: 15px solid rgba(var(--rgb-theme-default), 0.8);
            }
          }
        }
      }
      ul.framworks-list {
        li {
          width: 100px;
          height: 100px;
          img {
            height: 40px;
          }
        }
      }
    }
    // Faq Section
    .faq-section{
      .title{
        p{
          text-align: center;
          font-size: 14px;
        }
      }
    }
    // footer 
    .landing-footer {
      .footer-contain {
        img {
          height: 80px;
          margin-bottom: 10px;
        }
        h2 {
          margin-bottom: 5px;
        }
        p {
          margin-bottom: 10px;
        }
        .star-rate {
          margin-bottom: 25px;
        }
        .btn-footer {
          .btn {
            padding: 10px 25px;
            font-size: 14px;
            line-height: 1;
          }
        }
      }
    }
  }
}
@media(max-width:575px) {
  .landing-page {
    .custom-container {
      padding-left: 15px;
      padding-right: 15px;
    }
    // .marquee {
    //   .marquee-name {
    //     p{
    //       &.big-title{
    //         font-size: 30px;
    //       }
    //     }
    //   }
    // }
    .landing-home{
      .home-bg{
        .home-text{
          h2{
            .line-text{
              width: 82px;
            }
          }
          p{ 
            &.description-name{
              font-size: 12px;
            }  
          }
        }
      } 
      .wave-vibe{
        left: -180%;
      }
    }
    // demo-section
    .demo-section {
      .demo-box {
        padding: 15px;
        .demo-title {
          h3 {
            font-size: 16px;
          }
        }
      }
    }
    // framework
    .framework {
      .nav {
        display: flex;
      }
    }
    // core feature
    .core-feature {
      .feature-box {
        padding: 30px 20px;
      }
    }
     // Faq Section
     .faq-section{
      .title{
        p{
          display: none;
        }
      }
      .faq-box{
        p{
          font-size: 14px;
        }
      }
    }
    // counter sec feature //  
    .counter-sec {
      .counter-box {
        height: auto;
        padding: 30px 20px;
        .count-number {
          width: 45px;
          height: 45px;
          margin-bottom: 10px;
          h3 {
            font-size: 18px;
          }
        }
      }
    }
  }
}
@media(max-width:480px) {
  .owl-theme{
    .owl-dots{
      .owl-dot{
        span{
          width: 6px !important;
          height: 6px !important;
          margin: 2px 2px !important;
        }
      }
    }
  }   
  .landing-page {
    .marquee{
      .marquee-name{
        p{
          &.big-title{
            // font-size: 23px;
            line-height: 80px;
          }
        }
      } 
    } 
    .landing-header{
      padding: 15px 6px;
    }
    .demo-section{
      .demo-block{
        padding: 0 12px;
      }
    }
    .title {
      h2 {
        font-size: 20px;
      }
    }
    // landing page
    .landing-home {
      .landing-home-contain {
        h2{
          font-size:22px;
        }
      }
    }
    // header 
    .landing-header {
      .buy-block {
        .toggle-menu {
          margin-left: 10px;
        }
      }
    }
    // framework  
    .framework {
      .nav {
        .nav-item {
          width: auto;
        }
        .nav-link {
          display: block !important;
          padding: 10px 15px;
          &.active {
            &:before {
              border-left: 10px solid transparent;
              border-right: 10px solid transparent;
              border-top: 5px solid rgba(var(--rgb-theme-default), 0.8);
            }
          }
          img {
            height: 20px;
            margin-right: unset;
          }
          .text-start {
            display: none;
          }
        }
      }
      ul.framworks-list {
        li {
          width: 110px;
          height: 110px;
          padding: 20px;
          img {
            height: 30px;
          }
        }
      }
    }
    // footer 
    .landing-footer {
      .footer-contain {
        .btn-footer {
          a:nth-child(n+2) {
            margin-left: 5px;
          }
          .btn {
            padding: 10px 15px;
          }
        }
      }
    }
  }

}
@media (max-width: 420px) {
  .landing-page{
    .landing-home{
      .landing-home-contain{
        .landing-icon{
          li{
            a{
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 417px) {
  .navbar-brand {
    margin-right: 5px;
  }
  .landing-page{
    .landing-home{
      .landing-home-contain {
        height: 680px;
        .btn-home-list {
          li {
            &:nth-child(n+2) {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
}
@media(max-width:360px) {
  .landing-page {
    .demo-section{
      .demo-box{
        .demo-title{
          &.btn-showcase{
            .btn{
              margin-right: 8px;
            }
          }
          .btn{
            padding: 6px 10px;
          }
        }
      }
    }
    .landing-footer {
      .footer-contain {
        .btn-footer {
          .btn {
            &:last-child {
              margin-top: 10px;
            }
          }
        }
      }
    }
    .marquee{
      .marquee-name{
        p{
          &.big-title{
            line-height: 65px;
          }
        }
      } 
    }
  }
}
/**=====================
     38. Landing CSS Ends
==========================**/