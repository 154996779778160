/**=====================
  5.9 Box-layout CSS start
==========================**/
.box-layout {
  background-image: url(../../../assets/images/other-images/boxbg.jpg);
  background-blend-mode: overlay;
  background-color: rgba($white, 0.5);
  .left-header {
    .left-menu-header{
      ul{
        &.header-left{
          li{ 
            &:last-child{
              display: none !important;
            }
          }
        }
      } 
    }
  } 
  .sale-card{
    .sale-date{
      a{
        span{
          display: none;
        }
      }
    }
  }
  .filter-cards-view{
    span{
      font-size: 14px;
    }
  } 
  .alert-primary{
    h4{
      color: var(--body-font-color); 
    }
    p{
      color: var(--body-font-color);
    }
  }
  .md-sidebar {
    .md-sidebar-toggle {
      display: block;
    }
    .md-sidebar-aside {
      position: absolute;
      top: 40px;
      left: 0;
      opacity: 0;
      visibility: hidden;
      z-index: 3;
      width: 280px;
      [dir="rtl"] & {
        left: unset;
        right: 0;
      }
      &.open { 
        opacity: 1; 
        visibility: visible;
      }
      // .card-body {
      //   height: 500px;
      //   overflow: auto;
      // }
    }
    .job-sidebar {
      background-color: $body-color;
      padding: 20px;
      overflow: auto;
      height: 500px;
      box-shadow: 0 0 21px 0 rgba(89, 102, 122, 0.1);
      .faq-accordion.default-according {
        margin-bottom: 0;
        .row {
          >div {
            &:last-child {
              .card {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
  .ecommerce-dashboard{
    .last-orders-table {
      tbody {
        tr {
          td {
            .user-data {
              span {
                text-overflow:ellipsis;
                overflow:hidden; 
                display: -webkit-box !important;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical; 
                white-space: normal;
              } 
            }
          }
        }
      }
    }
    .sale-card {
      .sale-date {
        a{ 
          display: none;
        }
      }
    }
    .sale-data {
      ul {
        li {
          .sale-value {
            .sales-value{
              span{
                &:last-child{
                  text-overflow:ellipsis;
                  overflow:hidden;
                  display: -webkit-box !important;
                  -webkit-line-clamp: 1;
                  -webkit-box-orient: vertical; 
                  white-space: normal;
                }
              }
            }
          }
        }
      }
    }
  }
  .project-summary{
    thead{ 
      tr{
        th{
          &:nth-child(5){
            display: none;
          }
          &:nth-child(4){
            display: none;
          }
        } 
      }
    }
    tbody{
      tr{
        td{
          &:nth-child(5){
            display: none;
          }
          &:nth-child(4){
            display: none;
          }
        }
        &:nth-child(4){
          border-bottom: none;
          td{
            border-bottom: none;
            padding-bottom: 0px;
          }
        }
        &:last-child{
          border-bottom: none;
          td{
            display: none;
            border-bottom: none;
          }
        }
      }
    }
  } 
  .file-sidebar {
    .card {
      .card-body {
        overflow: auto;
        
        height: 332px;
      }
    }
  }
  .summary-card {
    .summary-progressbar {
      ul {
        li {
          width: 50%;
          &:nth-child(3){ 
            border-right: none;
          }
          &:last-child {
            display: none;
          }
        }
      }
    }
  }
  .learning-block>div:first-child {
    order: 2;
  }
  .last-orders-table{
    thead{
      tr{
        th{
          &:nth-child(3){ 
            display: none;
          }
          &:nth-child(5){
            display: none;
          }
        }
      }
    }
    tbody{
      tr{
        td{
          &:nth-child(3){ 
            display: none;
          }
          &:nth-child(5){
            display: none;
          }
        }
      }
    }
  }
  @media only screen and (min-width: 1280px) {
    .page-wrapper.compact-wrapper {
      .page-body-wrapper {
        div.sidebar-wrapper {
          .logo-wrapper {
            padding: 22px 25px;
          }
          .logo-icon-wrapper {
            padding: 22px 25px;
          }
        }
        div.sidebar-wrapper.close_icon {
          .logo-icon-wrapper {
            padding: 26px;
          }
        }
      }
    }
    
    .learning-filter { 
      order: -1;
    }
    .artist-table {
      .appointment-table.customer-table {
          table { 
            tr {
                td {
                    &:last-child {
                      min-width: 100px;
                    }
                }
            }
          }
      }
    }
    &.dark-only {
      .page-wrapper {
        &.horizontal-wrapper {
          .page-body-wrapper {
            .sidebar-wrapper {
              .sidebar-main {
                #sidebar-menu {
                  background-color: $dark-body-background;
                }
              }
            }
          }
        }
      }
    }
    .email-wrap {
      .email-left-aside {
        .email-app-sidebar {
          .d-flex {
            display: block !important;
            .media-size-email {
              width: 100%;
              text-align: center;
              .mr-3 {
                margin-right: 0 !important;
              }
            }
            .flex-grow-1 {
              text-align: center;
            }
          }
        }
      }
    }
    .widget-1 {
      &.mb-xl-0 {
        margin-bottom: 25px !important;
      }
    }
    .radial-progress-card {
      .radial-chart-wrap {
        margin-right: -10px;
      }
    }
    .currency-widget {
      div[class*=bg-light-] {
        display: none;
      }
    }
    .box-order {
      .order-1 {
        order: 1 !important;
      }
    }
    .activity-card {
      .appointment-table {
        &.customer-table {
          height: 187px;
        }
      }
    }
    .transaction-table {
      tr {
        td {
          &:first-child {
            min-width: 150px;
          }
        }
      }
    }
    .welcome-card {
      .welcome-img {
        height: 170px;
        bottom: -22px;
      }
    }
    .comment-box {
      ul {
        ul {
          margin-left: 0;
        }
      }
    }
    .blog-page {
      .blog-box {
        &.blog-shadow {
          height: 455px;
          img {
            height: 455px;
          }
        }
      }
    }
    .blog-box {
      .blog-details {
        .blog-social {
          margin-top: 12px;
          li {
            &:first-child {
              padding-right: 8px;
            }
            + li {
              padding-left: 8px;
            }
          }
        }
      }
      &.blog-list {
        .blog-details {
          margin: 10px;
        }
      }
      .blog-details-main {
        .blog-social {
          li {
            padding: 0px 5px;
          }
        }
      }
    }
    .purchase-card {
      img {
        margin: -210px auto 0;
        width: 80%;
      }
      &.discover {
        margin-top: 90px;
        img {
          margin-top: -90px;
          width: 64%;
        }
      }
    }
    .apache-cotainer-large,
    #line-visual,
    #line-visulH {
      >div,
      canvas {
        width: 100% !important;
      }
    }
    .page-wrapper {
      .page-header {
        .header-wrapper {
          .nav-right {
            > ul  {
              > li {
                &:first-child {
                  display: none;
                }
              }
            }
          }
          // .serchinput {
          //   display: block !important;
          // }
        }
      }
    }
    .page-wrapper {
      &.horizontal-wrapper {
        .header-logo-wrapper {
          display: none;
        }
      }
    }
    .left-header {
      .left-menu-header {
        ul {
          &.header-left {
            li {
              span {
                &.f-w-700 {
                  padding: 6px 10px;
                }
              }
            }
          }
        }
      }
    }
  .page-wrapper {
    .page-header {
      .header-wrapper {
        padding: 18px 30px;
        .nav-right {
          > ul {
            > li {
              padding: 6px 8px;
            }
          }
        }
      }
    }
  }
    .page-wrapper,
    &.page-wrapper {
      &.box-layout {
        padding-top: 40px;
        margin-top: 0 !important;
      }
      &.compact-wrapper {
        margin-top: $box-layout-space;
        .page-body-wrapper {
          .sidebar-wrapper {
            width: $sidebar-compact-width;
            top: $box-layout-space;
            border-radius: 10px 0 0 10px;
            &.close_icon {
              ~app-footer {
                footer {
                  width: 1214px;
                  left: 48%;
                  margin-left: 71px;
                  padding-left: 52px !important;
                }
              }
              ~.footer-fix {
                padding-left: 0; 
              }
              .sidebar-main {
                .sidebar-links {
                  height: calc(100vh - 191px);
                }
              }
            }
            .logo-wrapper {
              box-shadow: 0 8px 6px -6px rgba(89, 102, 122, 0.1);
              border-radius: 10px 0 0 0;
            }
            .sidebar-main {
              .sidebar-links {
                margin-top: 10px;
                margin-bottom: 20px;
                height: calc(100vh - 191px);
              }
            }
          }
          .page-body {
            min-height: calc(100vh - 130px);
            margin-top: 0;
            top: 80px;
            overflow: scroll;
            max-height: calc(100vh - 220px);
            padding-bottom: 80px;
            margin-left: 246px;
            &::-webkit-scrollbar-track {
              -webkit-box-shadow: inset 0 0 6px $light-gray;
            }
            &::-webkit-scrollbar {
              width: 6px;
            }
            &::-webkit-scrollbar-thumb {
              background-color: rgba(var(--rgb-theme-default), 0.15);
            }
          }
        }
      }
      &.compact-sidebar {
        .page-header {
          padding-left: 148px;
        }
        .page-body-wrapper {
          margin-top: 40px;
          div.sidebar-wrapper {
            top: 40px;
            &.close_icon {
              display: none;
              transition: 0.5s;
            }
            .sidebar-main {
              .sidebar-links {
                height: calc(100vh - 166px);
                margin: 0;
                li {
                  .sidebar-submenu,
                  .mega-menu-container {
                    left: 470px;
                    top: 40px;
                    height: calc(100vh - 84px);
                  }
                  a {
                    &.active {
                      ~.sidebar-submenu,
                      ~.mega-menu-container {
                        animation: unset;
                      }
                    }
                  }
                }
              }
            }
          }
          .footer {
            margin-left: 0;
          }
          .page-body {
            min-height: calc(100vh - 90px);
            margin-top: 0;
            top: 80px;
            overflow: scroll;
            max-height: calc(100vh - 170px);
            padding-bottom: 80px;
            &::-webkit-scrollbar-track {
              -webkit-box-shadow: inset 0 0 6px $light-gray;
            }
            &::-webkit-scrollbar {
              width: 6px;
            }
            &::-webkit-scrollbar-thumb {
              background-color: rgba(var(--rgb-theme-default), 0.15);
            }
          }
        }
        &.compact-small {
          .page-header {
            padding-left: 90px;
            &.close_icon {
              padding-left: 0;
            }
          }
          .page-body-wrapper {
            div.sidebar-wrapper {
              .sidebar-main {
                .sidebar-links {
                  li {
                    .sidebar-submenu,
                    .mega-menu-container {
                      left: 410px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.horizontal-wrapper {
        &:before {
          display: none;
        }
        .page-body-wrapper {
          margin-top: 40px;
          .page-body {
            margin-top: 70px;
            min-height: calc(100vh - 310px);
            max-height: calc(100vh - 230px);
            overflow: scroll;
            &::-webkit-scrollbar-track {
              -webkit-box-shadow: inset 0 0 6px $light-gray;
            }
            &::-webkit-scrollbar {
              width: 6px;
            }
            &::-webkit-scrollbar-thumb {
              background-color: rgba(var(--rgb-theme-default), 0.15);
            }
          }
          .footer {
            padding-left: 15px !important;
          }
          .sidebar-wrapper {
            width: 1280px;
            top: 72px;
            margin: 0;
            overflow-x: unset;
            position: relative;
            .sidebar-main {
              .sidebar-links {
                .simplebar-mask {
                  top: 4px;
                }
              }
              #sidebar-menu {
                left: 0%;
                transform: translate(0, 0);
                top: 125px;
                background-color: $white;
              }
            }
          }
        }
        .page-header {
          padding-left: 0;
        }
      }
      &.modern-type {
        .page-header {
          padding-left: 0px;
          .header-wrapper {
            .left-header {
              width: 36%;
            }
            .nav-right {
              width: 46%;
            }
          }
        }
        .page-body-wrapper {
          .page-body {
            margin-left: 260px;
          }
          .sidebar-wrapper {
            top: 120px;
            .sidebar-main {
              .sidebar-links {
                height: calc(100vh - 311px);
              }
            }
            &.close_icon {
              .sidebar-main {
                .sidebar-links {
                  height: calc(100vh - 316px);
                }
              }
              ~footer {
                width: 1280px;
                left: 50%;
                margin-left: 0;
              }
            }
          }
          .footer {
            margin-left: 0;
          }
        }
      }
      .page-header {
        max-width: 1280px;
        margin: 0 auto;
        left: 0;
        right: 0;
        transition: $sidebar-transition;
        padding-left: 246px;
        margin-top: $box-layout-space;
        border-radius: 10px;
        width: unset;
        &.close_icon {
          padding-left: 71px;
          margin-left: auto;
          width: calc(100% - 0px);
          transition: $sidebar-transition;
        }
        .header-wrapper {
          border-radius: 10px 10px 0 0;
        }
      }
      &.horizontal-wrapper {
        .page-header {
          .header-wrapper {
            .nav-right,
            .left-header {
              width: 36%;
            }
          }
        }
      }
      &.material-type {
        .page-body-wrapper {
          margin-top: 0;
          .page-body {
            margin-top: 0;
          }
          .sidebar-wrapper {
            top: 0;
          }
        }
      }
      &.material-icon {
        .page-header {
          &.close_icon {
            margin-top: 40px !important;
          }
        }
        .page-body-wrapper {
          margin-top: 0;
          .page-body {
            top: 0;
            min-height: calc(100vh - 170px);
            padding-bottom: 40px;
          }
        }
      }
      &.advance-layout {
        .page-header {
          box-shadow: 0px 0px 10px rgba($black, 0.07);
        }
        .page-body-wrapper {
          margin-top: 0;
        }
      }
      .page-body-wrapper {
        border-radius: 10px 10px 0 0;
        width: 1280px; 
        box-shadow: 4px 11px 25px rgba(0, 0, 0, 0.07);
        margin: 0 auto;
        overflow: hidden;
        .todo {
          .todo-list-wrapper {
            #todo-list {
              li {
                .task-responsive {
                  min-width: 1087px;
                  overflow: auto;
                }
              }
            }
          }
        }
        .active-order-table {
          max-width: 443px;
          overflow: auto;
          table {
            tbody {
              tr {
                td {
                  p {
                    width: 100px;
                  }
                }
              }
            }
          }
        }
        .activity {
          .d-flex {
            .gradient-round {
              &.gradient-line-1 {
                &:after {
                  height: 57px;
                  bottom: -64px;
                }
              }
              &.small-line {
                &:after {
                  height: 36px;
                  bottom: -43px;
                }
              }
              &.medium-line {
                &:after {
                  height: 40px;
                  bottom: -48px;
                }
              }
            }
          }
        }
        footer {
          width: 1280px;
          margin: 0 auto;
          padding-left: 275px !important;
          margin-bottom: 38px !important; 
          position: fixed;
          left: 50%;
          transform: translate(-50%, 0);
          border-radius: 0 0 10px 10px; 
        }
        .blockquote-footer {
          margin-left: 0;
          width: 100%;
        }
        .footer-fix {
          padding-left: 230px;
        }
        .chat-box {
          .chat-history {
            .call-content {
              display: flex;
              align-items: center;
              justify-content: center;
              min-width: 300px;
              >div {
                z-index: 8;
                background-color: rgba(255, 255, 255, 0.75);
                background-blend-mode: overlay;
                width: 100%;
                padding: 30px;
                left: 15px;
              }
              button {
                width: 40%;
                font-size: 14px;
                margin: 0 auto;
              }
            }
            .call-icons {
              margin-top: 20px;
              margin-bottom: 20px;
              ul {
                li {
                  border: 1px solid #717171;
                  width: 50px;
                  height: 50px;
                  padding: 8px;
                }
              }
            }
            .receiver-img {
              margin-top: 30px;
              margin-bottom: 30px;
              img {
                width: 38%;
              }
            }
            .total-time {
              h2 {
                font-size: 28px;
                color: #717171;
              }
            }
          }
        }
        canvas {
          &#myLineCharts {
            width: 100%;
          }
        }
        .chat-right-aside {
          flex: 0 0 60%;
          max-width: 60%;
          overflow: hidden;
        }
        .caller-img {
          position: absolute;
          width: 100%;
          max-width: 100%;
          left: 15px;
          img {
            opacity: 0.7;
          }
        }
        .browser-widget {
          img {
            height: 65px;
          }
        }
        .custom-card {
          .card-header {
            img {
              margin-top: -73px;
            }
          }
          .card-profile {
            img {
              height: 130px;
              top: -17px;
            }
          }
        }
        .select2 {
          width: 901.781px;
        }
      }
      ul {
        &.close_icon {
          >li {
            label {
              padding: 0;
              &:after {
                display: none;
              }
            }
          }
        }
      }
      .bookmark {
        ul {
          margin-right: -1px;
        }
      }
      #batchDelete {
        .jsgrid-grid-header {
          .jsgrid-table {
            tr {
              th {
                .btn {
                  padding-left: 20px;
                  padding-right: 20px;
                }
              }
            }
          }
        }
      }
      .btn-group-showcase {
        .btn-radio {
          .btn-group {
            .radio {
              input[type="radio"] {
                display: none;
              }
            }
          }
        }
      }
      .d-flex.bitcoin-graph {
        display: block;
        .top-bitcoin {
          display: inline-block;
          vertical-align: middle;
        }
        .flex-grow-1 {
          margin-top: 30px;
          .bitcoin-content {
            .bitcoin-numbers {
              h6 {
                font-size: 14PX;
              }
            }
            &.text-right {
              text-align: center !important;
            }
          }
        }
      }
      .alert {
        &.inverse {
          p {
            max-width: 238px;
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .auth-bg-effect {
        .second-effect {
          left: 55%;
        }
      }
      .auth-bg-video {
        video {
          min-width: 67%;
          width: 67%;
        }
      }
      .page-builder {
        .ge-canvas.ge-layout-desktop {
          margin-top: $box-layout-space;
        }
        .ge-addRowGroup {
          margin-bottom: 10px;
        }
      }
      .pricing-wrapper-card {
        padding: 50px 20px;
      }
      .card {
        .blog-box {
          &.blog-grid {
            &.set-min-height {
              min-height: 400px;
            }
          }
        }
      }
      .flot-chart-placeholder {
        &#donut-color-chart-morris-daily {
          min-height: 430px;
        }
      }
      .flot-chart-placeholder {
        &#donut-color-chart-morris {
          min-height: 430px;
        }
      }
      .box-col-12 {
        flex: 0 0 100%;
        max-width: 100%;
      }
      .box-col-6 {
        flex: 0 0 50%;
        max-width: 50%;
      }
      .box-col-3 {
        flex: 0 0 25%;
        max-width: 25%;
      }
      .box-col-7 {
        flex: 0 0 60%;
        max-width: 60%;
      }
      .box-col-5 {
        flex: 0 0 40%;
        max-width: 40%;
      }
      .box-col-8 {
        flex: 0 0 70%;
        max-width: 70%;
      }
      .box-col-4e {
        flex: 0 0 30%;
        max-width: 30%;
      }
      .box-col-4 {
        flex: 0 0 33.33%;
        max-width: 33%;
      }
      .box-col-8e {
        flex: 0 0 66.67%;
        max-width: 66.67%;
      }
      .box-col-none {
        display: none !important;
      }
      .chat-box {
        .chat-right-aside {
          .chat {
            .chat-header {
              .chat-menu-icons {
                li {
                  a {
                    i {
                      font-size: 19px;
                    }
                  }
                }
              }
            }
          }
          flex: 0 0 100%;
          max-width: calc(100% - 15px);
          overflow: hidden;
        }
        .toogle-bar {
          display: inline-block;
          margin-right: 0 !important;
        }
      }
      .chat-menu {
        right: 0;
        border-top: 1px solid #ddd;
        opacity: 0;
        transform: translateY(-30px);
        visibility: hidden;
        top: 81px;
        position: absolute;
        z-index: 9;
        background-color: #fff;
        transition: all linear 0.3s;
        &.show {
          opacity: 1;
          visibility: visible;
          transform: translateY(0px);
          transition: all linear 0.3s;
          padding-bottom: 25px;
        }
      }
      .ct-10.total-chart {
        .ct-chart-bar {
          .ct-series {
            .ct-bar {
              stroke-width: 23px !important;
            }
          }
        }
      }
      .social-app-profile {
        #friends {
          .box-col-4 {
            flex: 0 0 30%;
            max-width: 30%;
          }
        }
      }
      .email-wrap {
        .email-body {
          .email-compose {
            .cke_contents {
              &.cke_reset {
                max-height: 165px;
              }
            }
          }
        }
        .row {
          .col-xl-6 {
            padding-left: 12px;
          }
          .col-xl-3 {
            +.col-xl-3 {
              padding-right: 12px;
            }
          }
        }
        .email-right-aside {
          .email-body {
            .inbox {
              height: 644px;
            }
          }
        }
        .email-content {
          .email-top {
            .user-emailid {
              &:after {
                right: -10px;
              }
            }
          }
        }
      }
      .todo {
        .notification-popup {
          right: 320px;
        }
      }
      .touchspin {
        padding: 0 10px;
      }
      .vertical-menu-main {
        width: 1280px;
        margin: 0 auto;
        left: 0;
        right: 0;
        .mega-menu {
          width: 1050px !important;
          max-width: 1050px !important;
          left: -320px !important;
        }
      }
      .comingsoon {
        video {
          min-width: 67%;
          width: 67%;
        }
      }
    }
    // Dashboard-1 
    .default-dashboard {
      .review-slider {
        .review {
          .review-content {
            margin: 20px 0;
          }
          p {
            margin-top: 20px;
            font-size: 18px;
          }
        }
      }
      .sales {
        .d-flex {
          margin-left: 55px;
        }
        p {
          margin: 10px 0;
        }
      }
      .welcome-card {
        .card-body {
          height: 296px;
          .d-flex {
            margin-top: 45px;
          }
        }
      }
      .news-update {
        margin-bottom: 0;
      }
      .left-background {
        margin-bottom: 30px;
      }
      .product-chart {
        .shap-block {
          .rounded-shap {
            i {
              right: 38%;
              top: -53%;
            }
          }
        }
      }
    }
    // order

    .proorder-xl-1 {
      order: 1;
    }
    .proorder-xl-2 {
      order: 2;
    }
    .proorder-xl-3 {
      order: 3;
    }
    .proorder-xl-4 {
      order: 4;
    }
    .proorder-xl-5 {
      order: 5;
    }
    .proorder-xl-6 {
      order: 6;
    }
    .proorder-xl-7 {
      order: 7;
    }
    .proorder-xl-8 {
      order: 8;
    }
    .proorder-xl-9 {
      order: 9;
    }
    .proorder-xl-10 {
      order: 10;
    }
    .proorder-xl-11 {
      order: 11;
    }
    .proorder-xl-12 {
      order: 12;
    }
    .proorder-xl-13 {
      order: 13;
    }
    .pedding-start {
      padding-left: 0;
    }
    .pedding-end {
      padding-right: 0;
    }
    .margin-bottom {
      margin-bottom: 0 !important;
    }
    .online-order {
      margin-bottom: 30px !important;
    }
    // dashboard-03
    // .project-dashboard {
    //   .web-card {
    //     .flex-shrink-0 {
    //       .user-details {
    //         &.customers {
    //           ul {
    //             li {
    //               + li {
    //                 margin-left: -20%;
    //                 [dir="rtl"] & {
    //                   margin-left: unset;
    //                   margin-right: -20%;
    //                 }
    //               }
    //             }
    //           }
    //         }
    //       }
    //       .progress-value {
    //         .progress {
    //           width: 120px;
    //         }
    //       }
    //     }
    //     .comment {
    //       ul {
    //         li {
    //           padding: 6px 12px;
    //         }
    //       }
    //     }
    //   }
    //   .project-summary {
    //     table {
    //       thead {
    //         tr {
    //           th {
    //             &:nth-child(4) {
    //               display: none;
    //             }
    //             &:nth-child(5) {
    //               display: none;
    //             }
    //           }
    //         }
    //       }
    //       tbody {
    //         tr {
    //           td {
    //             &:nth-child(4) {
    //               display: none;
    //             }
    //             &:nth-child(5) {
    //               display: none;
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    //   .today-task {
    //     .clients-project {
    //       table {
    //         tbody {
    //           tr {
    //             td {
    //               .user-details {
    //                 &.customers {
    //                   ul {
    //                     li {
    //                       + li {
    //                         margin-left: -30% !important;
    //                         [dir="rtl"] & {
    //                           margin-left: unset !important;
    //                           margin-right: -30% !important;
    //                         }
    //                       }
    //                     }
    //                   }
    //                 }
    //               }
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    //   .ps-20 {
    //     padding-left: 0.5rem!important; 
    //     [dir="rtl"] & {
    //       padding-right: 0.5rem!important;
    //       padding-left: unset;
    //     }
    //   }
    //   .pe-30 {
    //     padding-right:  1rem!important;
    //     [dir="rtl"] & {
    //       padding-left:  1rem!important;
    //       padding-right: unset;
    //     }
    //   }
    // }
    .general-widget {
      .web-card {
        .flex-shrink-0 {
          .user-details {
            &.customers {
              ul {
                li {
                  + li {
                    margin-left: -20%;
                    [dir="rtl"] & {
                      margin-left: unset;
                      margin-right: -20%;
                    }
                  }
                }
              }
            }
          }
          .progress-value {
            .progress {
              width: 120px;
            }
          }
        }
        .comment {
          ul {
            li {
              padding: 6px 10px;
            }
          }
        }
      }
      .budget-card {
        .budget-spent {
          display: none;
        }
      }
      .offline-card {
        display: none;
      }
    }
  }
}
/**=====================
  5.9 Box-layout CSS Ends
==========================**/