@import "./../../../node_modules/bootstrap/scss/bootstrap.scss";

// Template vars
@import "utils/variables";

//rating scss //
@import"./../../../node_modules/ngx-bar-rating/themes/br-default-theme.scss";
@import"./../../../node_modules/ngx-bar-rating/themes/br-horizontal-theme.scss";
@import"./../../../node_modules/ngx-bar-rating/themes/br-movie-theme.scss";
@import"./../../../node_modules/ngx-bar-rating/themes/br-square-theme.scss";
@import"./../../../node_modules/ngx-bar-rating/themes/br-stars-theme.scss";
@import"./../../../node_modules/ngx-bar-rating/themes/br-vertical-theme.scss";

@import "./../../../node_modules/angular-calendar/scss/angular-calendar.scss";
@import "./../../../node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.theme.default.scss";
@import "./../../../node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.carousel.scss";

@import '@angular/cdk/overlay-prebuilt.css';
@import "@ng-select/ng-select/themes/default.theme.css";

@import "chartist/chartist-settings";
@import "chartist/chartist";

@import "./todo.scss";
@import "./photoswipe.scss";

@import "./flag-icon";
@import "./fontawesome.scss";
@import "./icofont.scss";
@import "./whether-icon.scss";
@import "./feather-icon.scss";
@import"./themify.scss";
@import "leaflet/dist/leaflet.css";
@import 'dropzone/dist/min/dropzone.min.css';
@import "./themes/responsive";
@import "./style.scss";