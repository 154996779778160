  /**=====================
    5.5 Search CSS Start
  ==========================**/
  .search-page {
    .pagination {
      .page-item {
        .page-link {
          padding: 5px 8px;

        }
      }
    }
    .theme-form {
      input {
        border: 1px solid var(--theme-default);
        padding-left: 20px;
        border-radius: 10px 0 0 10px;
        flex: 1 1 auto;
        &::placeholder {
          color: var(--theme-default);
        }
        &:focus {
          outline: none;
        }
      }
      .input-group {
        .btn {
          line-height: 32px;
          font-size: 16px;
        }
        .form-control-plaintext {
          background: transparent;
        }
      }
    }
    h5 {
      line-height: 1.4;
    }
    .nav-link {
      font-weight: 500;
      text-transform: uppercase;
    }
    .border-tab {
      &.nav-tabs {
        .material-border {
          border-bottom-width: 1px;
        }
        .nav-item {
          .nav-link {
            font-size: 15px;
            padding: 10px 18px;
          }
        }
      }
    }
    .d-flex {
      .flex-grow-1 {
        overflow: hidden;
      }
    }
    .info-block {
      padding: 30px;
      border-radius: 15px;
      border: 1px solid $light-color;
      a {
        color: #656565;
        margin-bottom: 3px;
        display: block;
      }
      h5 {
        color: var(--theme-default) !important;
      }
      +.info-block {
        margin-top: 20px;
      }
    }
    .border-tab.nav-tabs {
      align-items: left;
      justify-content: end;
      .nav-item {
        width: auto;
      }
    }
    .search-links {
      h5 {
        margin-bottom: 10px;
      }
    }
    p {
      text-transform: lowercase;
      margin-bottom: 0;
      color: #a5a2a2;
    }
    ul.search-info {
      li {
        display: inline-block;
        font-size: 12px;
        line-height: 1;
        color: #586082;
        +li {
          border-left: 1px solid lighten($dark-color, 50%);
          padding-left: 8px;
          margin-left: 8px;
        }
        i {
          color: $warning-color;
          &:not(:last-child) {
            margin-right: 4px;
          }
        }
      }
    }
    #video-links {
      .embed-responsive+.embed-responsive {
        margin-top: 30px;
      }
    }
  }
  .search-list {
    margin-bottom: 30px;
    width: auto;
    display: inline-flex;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid var(--theme-default);
    border: none;
    .nav-item {
      background-color: rgba(var(--rgb-theme-default), 0.9);
      &.bg-light-success {
        color: var(--white) !important;
        background-color: rgba($success-color, 0.9) !important;
      } 
      &.bg-light-secondary { 
        color: var(--white) !important;
        background-color: rgba(var(--rgb-theme-secondary), 0.9) !important;
      }
      .nav-link {
        border: none;
        padding: 0.7rem 1.5rem;
        // opacity: 0.5;
        color: var(--white);
        &.active {
          background-color: rgba(var(--rgb-theme-default), 0.9);
          color: var(--white); 
          opacity: 4;
        }
      }
      &.show,
      &.active {
        background-color: rgba(var(--rgb-theme-default), 0.1);
      }
    }
  }
  /**=====================
    5.5 Search CSS Ends
  ==========================**/