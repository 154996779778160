/**=====================
  2.3 Avatars CSS start
==========================**/
.avatars {
    .avatar {
        display: inline-block;
        margin-right: 10px;
        position: relative;
        width: auto;

        &:last-child {
            margin-right: 0;
        }
        &.ratio {
            img {
                width: auto;
            }
        }

        .status {
            position: absolute;
            height: 12%;
            width: 12%;
            border-radius: 100%;
            bottom: 12%;
            right: 4%;
        }
    }
}

.customers {
    ul {
        display: inline-block;

        li {
            img {
                border: 2px solid var(--white);
                transition: 0.5s;

                &:hover {
                    transform: translateY(-4px) scale(1.02);
                    transition: 0.5s;
                }
            }
            p{
                transition: 0.5s; 
                &:hover {
                    transform: translateY(-4px) scale(1.02);
                    transition: 0.5s;
                }
            }

            +li {
                margin-left: -7%;
            }
        }
    }

    &.avatar-group {
        margin-right: 30px;

        &:last-child {
            margin-right: 0;
        }
    }
}

.status-offline {
    background-color: $danger-color;
}

.status-online {
    background-color: $success-color;
}

.status-dnd {
    background-color: $warning-color;
}


.avatar-showcase {
    .avatars {
        .avatar {
            margin-bottom: 10px;
        }
    }

    .avatar-group {
        margin-bottom: 10px;
    }

    .ratio {
        >* {
            position: relative;
            top: unset;
            left: unset;
        }
    }
}

/**=====================
    2.3 Avatars CSS Ends
==========================**/