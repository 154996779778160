/**=====================
   3.34 Progress CSS Start
==========================**/
.progress-showcase {
	.progress {
		margin-bottom: 10px;
		&:last-child {
			margin-bottom: 0;
		}
	}
}
.progress-number {
	direction: ltr;
}
.sm-progress-bar {
	height: 8px;
}
.lg-progress-bar {
	height: 20px;
}
.progress-wrapper {
	height: 1px;
}
.progress-btn {
	background-color: $light-chartist-grid-color;
	border-color: $light-chartist-grid-color;
}
.small-progressbar {
	position: relative;
	overflow: visible;
	&::after {
		position: absolute;
		content: '';
		top: -2px;
		right: -9px;
		background-color: var(--theme-default); 
		width: 12px;
		height: 12px;
		border-radius: 100%;
		[dir="rtl"] & {
			left: -9px;
			right: unset;
		}
	}
	.progress-label {
		position: absolute;
		top: -26px;
		right: -6px;
		font-weight: 700;
		[dir="rtl"] & {
			right: unset;
			left: -6px;
		}
	}
	.animate-circle {
		position: absolute;
		top: -11px;
		right: -18px;
		height: 30px;
		width: 30px;
		border: 14px solid var(--theme-default);
		border-radius: 70px;
		animation: heart 1s ease-in-out;
		animation-iteration-count: infinite;
		[dir="rtl"] & {
			right: unset;
			left: -18px;
		}
	}
}
@keyframes heart {
	0% {
		transform: scale(0);
		opacity: 0;
	}
	25% {
		transform: scale(.1);
		opacity: 1;
	}
	50% {
		transform: scale(.5);
		opacity: .3;
	}
	75% {
		transform: scale(.8);
		opacity: .5;
	}
	100% {
		transform: scale(1);
		opacity: 0;
	}
}
.progress-b-space {
	.progress {
		margin-bottom: 35px;
	}
}
$colors: (
	primary: var(--rgb-theme-default),
	secondary: var(--rgb-theme-secondary),
	warning: $warning-color,
	info: $info-color,
	success: $success-color,
	danger: $danger-color,
	light: $light-color,
	dark: $dark-color
);

//btn color
@each $key,
$val in $colors {
	.progress-striped-#{$key} {
		background-color: rgba($val, 0.1);

		.progress-bar {
			background-image: linear-gradient(120deg, rgba($val, 1) 25%, transparent 25%, transparent 50%, rgba($val, 1) 50%, rgba($val, 1) 75%, transparent 75%, transparent);
			background-size: 16px 30px;
			--bs-progress-bar-bg: rgba($val, 0.1);
		}
	}
}
/**=====================
   3.34 Progress CSS Ends
==========================**/