/**=====================
  5.2 Grid CSS Start
==========================**/
.grid-showcase {
  margin-bottom: -10px;
  span {
    display: block;
    border: 1px solid $light-color;
    padding: 10px;
    margin-bottom: 10px;
    text-align: center;
    background-color: rgba(var(--rgb-theme-default), 0.05) !important;
    color: var(--theme-default) !important;
    font-weight: 500;
  }
}
.grid-align {
  margin-bottom: -30px;
  .row {
    padding: 15px 0;
    min-height: 10rem;
    border: 1px solid rgba(var(--rgb-theme-default), 0.02);
    margin-bottom: 30px;
    background-color: rgba(var(--rgb-theme-default), 0.02) !important;
  }
}
/**=====================
    5.2 Grid CSS Ends
==========================**/