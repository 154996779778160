// Mixins
// --------------------------

// FONT / MARGINS / FONT SIZE / COLOUR
@mixin icon($i, $m:10px, $fs:18px, $c:inherit) {
  &:before { @include icon-themify-icons(); content: $i; margin-right: $m; font-size: $fs; color:$c;}
}
@mixin iconafter($i, $m:10px, $fs:18px, $c:inherit) {
  &:after { @include icon-themify-icons(); content: $i; margin-left: $m; font-size: $fs; color:$c; }
}

@mixin icon-themify-icons() {
  font-family: 'themify';
speak: none;
font-style: normal;
font-weight: normal;
font-variant: normal;
text-transform: none;
line-height: 1;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
  margin-right: .3em; // fixes ie7 issues
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}


@mixin icon-stack($width: 2em, $height: 2em, $top-font-size: 1em, $base-font-size: 2em) {
  .icon-stack {
    position: relative;
    display: inline-block;
    width: $width;
    height: $height;
    line-height: $width;
    vertical-align: -35%;
    [class^="icon-"],
    [class*=" icon-"] {
      display: block;
      text-align: center;
      position: absolute;
      width: 100%;
      height: 100%;
      font-size: $top-font-size;
      line-height: inherit;
      line-height: $height;
    }
    .icon-stack-base {
      font-size: $base-font-size;
      line-height: #{2em / 2em}em;
    }
  }
}
