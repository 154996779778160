$color_1: #fff;
$color_2: var(--theme-default);
$color_3: #999999;
$color_4: #59667a;
$color_5: #15202b;
$color_6: #efefef;
$background-color_1: #F8F8F8;
$background-color_2: transparent;

/*====todo css start====*/
@-webkit-keyframes taskHighlighter {
	0% {
		background: rgba(68, 102, 242, 0.5);
	}
	100% {
		background: #fff;
	}
}
@keyframes taskHighlighter {
	0% {
		background: rgba(68, 102, 242, 0.5);
	}
	100% {
		background: #fff;
	}
}
/*====todo css end====*/
.todo {
	.action-box {
		background: transparent;
		height: 20px;
		width: 20px;
		display: inline-block;
		text-align: center;
		cursor: pointer;
		transition: all 300ms ease;
		.icon {
			vertical-align: 0;
		}
	}
	.action-box.completed {
		background: #DC3545;
		border: 1px solid #DC3545;
		border-radius: 4px;
		.icon {
			color: $color_1;
			font-size: 14px;
		}
	}
	.action-box.large {
		height: 26px;
		width: 26px;
		.icon {
			font-size: 16px;
			vertical-align: -4px;
			.icon-trash {
				color: $color_2;
			}
			.icon-check {
				color: $color_2;
			}
		}
	}
	.todo-list-wrapper {
		width: 100%;
		margin: 0 auto;
		overflow: auto;
		box-sizing: border-box;
		#todo-list {
			list-style: none;
			margin: 0;
			padding: 0;
			li {
				margin: 0;
				padding: 0;
				width: 100%;
				&:last-child {
					.task-container {
						border-bottom: 0;
						padding-bottom: 0;
					}
				}
				.task-container {
					display: flex;
					justify-content: space-between;
					padding: 10px 0;
					width: 100%;
					border-bottom: 1px solid #EDEDED;
					box-sizing: border-box;
					position: relative;
					transition: background 200ms ease;
					.d-flex {
						align-items: center;
						div {
							text-align: right;
						}
					}
					.task-label {
						display: table;
						font-size: 16px;
						font-weight: 400;
						vertical-align: middle;
						color: $color_3;
						word-break: break-word;
						padding-top: 5px;
					}
					.task-action-btn {
						display: table-cell;
						vertical-align: middle;
						text-align: right;
						.action-box {
							border: 1px solid transparent;
							&:hover {
								background: #fff;
								border-radius: 4px;
								.icon {
									color: $color_4;
								}
							}
						}
					}
					&:hover {
						h4 {
							color: $color_5;
							transition: all 0.3s ease;
						}
					}
				}
			}
			li.completed {
				.task-container {
					.complete-btn {
						border: 1px solid var(--theme-default);
						border-radius: 4px;
						.icon {
							font-weight: bold;
							i {
								color: $color_2;
							}
						}
						&:hover {
							background: #fff;
							border: 1px solid var(--theme-default);
							.icon {
								color: $color_1;
							}
						}
					}
					.task-label {
						text-decoration: line-through;
						color: $color_2;
					}
				}
			}
			li.new {
				.task-container {
					animation: taskHighlighter 2000ms linear 1;
				}
			}
		}
		.todo-list-header {
			h5 {
				text-align: center;
				color: $color_6;
			}
		}
	}
	.notification-popup {
		position: fixed;
		top: 100px;
		right: 10px;
		width: 300px;
		display: inline-block;
		background: var(--theme-default);
		border: 1px solid var(--theme-default);
		color: $color_1;
		padding: 20px;
		opacity: 0.9;
		border-radius: 2px;
		box-sizing: border-box;
		transition: all 300ms ease;
		p {
			margin-top: 0;
			margin-bottom: 0;
			line-height: 1;
		}
		.task {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			max-width: 100%;
			display: inline-block;
			color: $color_1;
			padding: 0 4px;
		}
		.notification-text {
			font-size: 14px;
			display: inline-block;
			overflow: hidden;
			color: $color_1;
		}
	}
	.notification-popup.success {
		background: var(--theme-default);
		border: 1px solid var(--theme-default);
	}
	.notification-popup.hide {
		opacity: 0;
		visibility: hidden;
	}
}
.todo-wrap {
	.card-header.b-bottom {
		border-bottom: 1px solid #EDEDED;
	}
	.card-header {
		.todo-list-header {
			.new-task-wrapper {
				input {
					padding: 12px 28px;
					background-color: $background-color_1;
					border: 1px solid #dee2e6;
				}
			}
			.add-new-task-btn {
				display: flex;
				align-items: center;
				z-index: 0;
			}
		}
	}
	.todo-list-body {
		.assign-name {
			opacity: 0.4;
			font-size: 13px;
			font-weight: 400;
		}
	}
	.left-bookmark {
		ul {
			li {
				.iconbg {
					padding: 8px;
					border-radius: 30px;
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}
		}
	}
}
.todo-wrap.email-wrap {
	.email-app-sidebar {
		.main-menu {
			>li {
				button.d-block {
					text-align: left;
				}
				+ {
					li {
						padding-top: 12px;
					}
				}
				a {
					line-height: 0;
					padding: 0 16px;
					align-items: center;
					.badge {
						color: $color_1;
						padding: 5px 6px 4px 7px;
					}
					&:hover {
						background-color: $background-color_2;
						.badge {
							color: $color_1;
						}
					}
				}
			}
		}
	}
}
