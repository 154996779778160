.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-list i.fa-thumb-tack{
  right: 30px;
}

.layout-rigth{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
}
.layout-parallel{
  display: flex;
  justify-content: space-between;
  gap: 16px;
  flex-wrap: wrap;
}

.tab-boton{
  margin-bottom: 30px;
  width: auto;
  display: inline-flex;
  border-radius: 10px;
  overflow: hidden;
  border: none;

  .nav-item{
    background-color: #e2e2e2;;
    .nav-link.active{
      background-color: #262626;
      color: #ffffff;
    }
  }
}

.title-line{
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 18px;
  .line{
    flex: 1;
    width: 100%;
    height: 2px;
    border-radius: 20px;
    background: #000;
  }
}

.image-circle{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}
.mr-2{
  margin-right: 8px;
}

.layout_filter{
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 16px;
  padding: 0px 18px;
  margin-bottom: 18px;
  .filter-item{
    flex: 300px 0 1;
  }
}
