/**=====================
     2.2 Alert CSS start
==========================**/
.notify-alert {
  .close {
      background: unset;
      border: none;
      font-size: 25px;
      [dir="rtl"] & {
          right: unset !important;
          left: 10px !important;
      }
  }
  &.alert-light {
      .close {
          color: $theme-body-font-color;
      }
  }
}
.alert {
  border-radius: $alert-border-radius;
  padding: 15px;
  svg {
    vertical-align: middle;
  }
  .progress {
    margin-top: $alert-padding;
  }
  [data-notify='icon'] {
    margin-right: 10px;
    line-height: 20px;
    position: absolute;
  }
  [data-notify='message'] {
    display: inline-block;
    width: 100%;
  }
  i {
    margin-right: 5px;
    font-size: $alert-msg-icon-size;
  }
  p {
    margin-bottom: 0;
  }
  .close {
    opacity: 1;
    span {
      font-size: 24px;
      font-weight: 400;
      display: inline-block;
      border-radius: 3px;
      text-shadow: none;
      padding: 0 5px;
      padding-right: 0;
    }
  }
}
.alert-dismissible {
  padding-right: 2.5rem;
  [dir="rtl"] & {
    padding-right: 15px;
    padding-left: 2.5rem;
  }
  .close {
    padding: 10px 1.25rem;
    top: 4px;
    padding-right: 13px;
    opacity: 0.5;
    transition: all 0.3s ease;
    &:hover {
      transition: all 0.3s ease;
      opacity: 1;
      color: inherit;
    }
  }
}
.card-body {
  button {
    &:focus {
      outline: none;
    }
  }
  .alert {
    &:last-child {
      margin-bottom: 0;
    }
    svg {
      width: 14px;
      height: 14px;
      margin-right: 4px;
      position: absolute;
      top: 18px;
      &~p {
        padding-left: 20px;
      }
    }
  }
}
strong {
  display: inline-block;
  &~p {
    display: inline-block;
  }
}
$alert-name: primary,
  secondary,
  success,
  danger,
  warning,
  info,
  light,
  dark;
$alert-color : var(--rgb-theme-default),
var(--rgb-theme-secondary),
$success-color,
$danger-color,
$warning-color,
$info-color,
$light-color,
$dark-color;
@each $var in $alert-name {
  $i: index($alert-name, $var);
  .alert-#{$var} {
    background-color: rgba(nth($alert-color, $i), 0.8);
    border-color: rgba(nth($alert-color, $i), 0.9);
    color: $white;
    .progress {
      height: 5px;
      background-color: rgba(nth($alert-color, $i), 1);
      border-radius: 0;
    }
    .progress-bar {
      background-color: rgba(nth($alert-color, $i), 1);
    }
    .btn-close {
      filter: brightness(1) invert(1);
    }
    .alert-link {
      color: rgba(nth($alert-color, $i), 1);
      @if($var =="light") {
        color: $dark-color;
      }
    }
    @if($var =="light") {
      color: $dark-color;
      background-color: $light-color;
      .btn-close {
        filter: unset;
      }
    }
    hr {
      border-top-color: rgba(nth($alert-color, $i), 1);
    }
  }
  .border-#{$var} {
    border-color: rgba(nth($alert-color, $i), 1) !important;
  }
  // dashboard-7
  .income-wrapper {
    ul {
      li {
        .income-dot {
          &.dot-#{$var} {
            background-color: rgba(nth($alert-color, $i), 1);
          }
        }
      }
    }
  }
  .list-group-horizontal {
    &-sm,
    &-md,
    &-lg,
    &-xl,
    &-xxl {
      .list-group-item {
        &.border-left-#{$var} {
          border-left: 4px solid rgba(nth($alert-color, $i), 1);
          [dir="rtl"] & {
            border-left: unset;
            border-right: 4px solid rgba(nth($alert-color, $i), 1);
          }
        }
      }
    }
  }
  // touchspin 
  .touchspin-wrapper {
    .btn-touchspin {
      &.touchspin-#{$var} {
        background-color: rgba(nth($alert-color, $i), 1);
        border: 1px solid rgba(nth($alert-color, $i), 1);
        color: $white;
      }
      &.spin-border-#{$var} {
        background-color: unset;
        border: 1px solid rgba(nth($alert-color, $i), 1);
        color: rgba(nth($alert-color, $i), 1);
      }
    }
    input[type='number'] {
      &.spin-outline-#{$var} {
        outline: 1px solid rgba(nth($alert-color, $i), 1);
        border: none;
        border-radius: 3px;
      }
    }
  }
  .pre-post-touchspin {
    .btn-touchspin {
      &.touchspin-#{$var} {
        background-color: rgba(nth($alert-color, $i), 1);
        border: 1px solid rgba(nth($alert-color, $i), 1);
        color: $white;
      }
    }
  }
  // switches
  .form-switch {
    .form-check-input {
      &.switch-#{$var} {
        &:checked {
          background-color: rgba(nth($alert-color, $i), 1);
          border-color: rgba(nth($alert-color, $i), 1);
        }
      }
    }
  }
  .payment-second {
    .mega-icons {
      &.stroke-#{$var} {
        stroke: rgba(nth($alert-color, $i), 1);
      }
    }
  }
  .toast-#{$var} {
    color: rgba(nth($alert-color, $i), 1) !important;
  }
  .list-group-horizontal {
    &-sm,
    &-md,
    &-lg,
    &-xl,
    &-xxl {
      .list-group-item {
        &.border-left-#{$var} {
          border-left: 4px solid rgba(nth($alert-color, $i), 1);
        }
      }
    }
  }
  // Radio-toggle
  .main-radio-toggle {
    .btn-check {
      &.radio-light-#{$var} {
        &:focus {
          +.btn {
            box-shadow: 0 0 0 4px rgba(nth($alert-color, $i), 0.55);
          }
        }
      }
    }
  }
  // radio button
  .fill-radios {
    .radio {
      &.radio-#{$var} {
        input[type='radio']:checked {
          &+label {
            &::before {
              border: 10px solid rgba(nth($alert-color, $i), 1);
            }
            &::after {
              background-color: rgba(nth($alert-color, $i), 1);
            }
          }
        }
      }
    }
  }
  .list-group {
    .form-check-input:checked[type=checkbox],
    [type=radio] {
      &.checkbox-#{$var} {
        background-color: unset;
        border-color: rgba(nth($alert-color, $i), 1);
        outline: 0;
        background-color: unset;
        &:focus,
        &:active,
        &.active {
          border-color: rgba(nth($alert-color, $i), 1);
          outline: 0;
          background-color: rgba(nth($alert-color, $i), 1);
        }
      }
    }
    .alert-#{$var}.light {
      background-color: rgba(nth($alert-color, $i), 0.4);
      border-color: rgba(nth($alert-color, $i), 0.7);
    }
    .alert-#{$var}.dark {
      .alert-link {
        color: $white;
        @if($var =="light") {
          color: $dark-color;
        }
      }
      background-color: rgba(nth($alert-color, $i), 1);
      border-color: rgba(nth($alert-color, $i), 1);
      color: $auth-bg-color;
      @if($var =="light") {
        color: $dark-color;
      }
    }
    .alert-#{$var}.outline,
    .alert-#{$var}.outline-2x {
      background-color: $transparent-color;
      border-color: rgba(nth($alert-color, $i), 1);
      color: rgba(nth($alert-color, $i), 1);
      .btn-close {
        filter: unset;
      }
      @if($var =="light") {
        color: $dark-color;
      }
    }
    .alert-#{$var}.inverse {
      &:before {
        top: 16px;
        content: "";
        position: absolute;
        left: 54px;
        width: 0;
        height: 0;
        border-left: 7px solid rgba(nth($alert-color, $i), 1);
        border-top: 7px solid transparent;
        border-bottom: 7px solid transparent;
        @media (max-width: 576px) {
          left: 45px;
        }
      }
      background-color: transparent;
      padding: 13px 20px 13px 65px;
      border-color: rgba(nth($alert-color, $i), 1);
      color: $theme-body-font-color;
      i {
        padding: 17px 20px;
        display: inline-block;
        background-color: rgba(nth($alert-color, $i), 1);
        color: $white;
        border-radius: 3px 0 0 3px;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        @media (max-width: 576px) {
          padding: 17px 14px;
        }
      }
    }
    .btn-close {
      filter: unset;
      top: -3px;
    }
  }
}
.outline-2x {
  border-width: 2px;
  padding: 11px 1.25rem;
}
.alert-icons {
  &.outline-2x {
    padding: 15px;
  }
  svg {
    position: absolute !important;
    top: 18px !important;
  }
}
.alert-dark,
.alert-info {
  .btn-close {
    filter: none;
  }
}
.border-left-wrapper {
  border-left: 4px solid $dark-color;
  background-color: #EAEBF2;
  color: black;
  $colors: (
    primary: $primary-color,
    secondary: $secondary-color,
    warning: $warning-color,
    info: $info-color,
    success: $success-color,
    danger: $danger-color,
    light: $light-color,
    dark: $dark-color
  );
  @each $key,
    $val in $colors {
    &.alert-light-#{$key} {
      color: if($key == 'light', rgba($dark-color, 0.8), rgba($val, 1));
      background-color: rgba($val, 0.1);
      border-left-color: rgba($val, 1);
    }
  }
}
$alert-light-name: primary,
  secondary,
  success,
  warning,
  info,
  light,
  danger,
  dark;
$alert-light-color: $light-primary,
  $light-secondary,
  $light-success,
  $light-warning,
  $light-info,
  $light-white,
  $light-danger,
  $light-dark;
@each $var in $alert-light-name {
  $i: index($alert-light-name, $var);
  .alert-light-#{$var} {
    color: var(--body-font-color);
    background-color: nth($alert-light-color, $i);
  }
}
.alert-arrow {
  min-width: 55px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  i {
    margin-right: 0;
  }
}
.ellipsis.content {
  @media (max-width:576px) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 294px;
  }
  @media (max-width:575px) {
    max-width: 180px;
  }
}
.alert-dismissible {
  display: flex;
  align-items: center;
  gap: 8px;
}
// flag-icons
.alert {
  &.notify-alert {
    &.alert-copy {
      display: flex !important;
    }
  }
}
/**=====================
   2.2 Alert CSS end
==========================**/