@media screen and (min-width: 992px) and (max-width: 1199px) {
    .sidebar-wrapper {
        .toggle-sidebar {
            display: none;
        }
    }
}
@media screen and (max-width: 991px) {
    .sidebar-wrapper {
        .sidebar-toggle {
            display: none;
            &.responsive__mobile {
                display: block;
            }
        }
    }
    .bg__close-responsive__mobile {
        display: block !important;
        &.show {
            opacity: 1;
            visibility: visible;
            pointer-events: auto;
        }
    }
}

@media screen and (max-width: 767px) {
    .swal2-container {
        .swal2-popup {
            .swal2-title {
                font-size: 20px;
            }
        }
    }             
}