$primary-color: #000000 !default;
$secondary-color: #EC1E2E !default;
$tertiary-color: #EC1E2E !default;

// Theme
$danger-color: #ff4a4a !default;
$warning-color: #ffd227 !default;
$info-color: #4ac6ff !default;
$success-color: #40d565 !default;

$font-color: #1f2f3e !default;

$font-title: 'Nunito Sans', sans-serif;
$font-subtext: 'Nunito Sans', sans-serif;
$font-text: 'Nunito Sans', sans-serif;