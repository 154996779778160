.br-vertical {
  --br-width: 120px;
  --br-height: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .br-units {
    width: 120px;
    flex-direction: column-reverse;
    margin: 10px 0;
  }

  .br-unit-inner {
    display: block;
    width: var(--br-width);
    height: var(--br-height);
    background-color: var(--br-active-color);
    margin-top: 1px;
    opacity: 0.25;

    &.br-active {
      opacity: 1;
    }
  }

  &.br-readonly {

    .br-active {
      opacity: 0.1;
    }
  }

  .br-text {
    font-size: 18px;
    font-weight: 600;
    color: var(--br-active-color);
  }
}
