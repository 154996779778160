// *------------------- CY WEB ADMIN - Custom Styles -----------------------
// New custom vars - Editar según cada proyecto
@import './vars';

// Template styles
@import "../../../../src/styles.scss";

// Custom
:root {
    --primary-color: #{$primary-color};
    --secondary-color: #{$secondary-color};
    --tertiary-color: #{$tertiary-color};
    // * for RGBA
    --rgb-primary-color: #{red($primary-color), green($primary-color), blue($primary-color)};
    --rgb-secondary-color: #{red($secondary-color), green($secondary-color), blue($secondary-color)};
    --rgb-tertiary-color: #{red($tertiary-color), green($tertiary-color), blue($tertiary-color)};

    --danger-color: #{$danger-color};
    --warning-color: #{$warning-color};
    --info-color: #{$info-color};
    --success-color: #{$success-color};

    // * for RGBA
    --rgb-danger-color: #{red($danger-color), green($danger-color), blue($danger-color)};
    --rgb-warning-color: #{red($warning-color), green($warning-color), blue($warning-color)};
    --rgb-info-color: #{red($info-color), green($info-color), blue($info-color)};
    --rgb-success-color: #{red($success-color), green($success-color), blue($success-color)};

    // * Font
    --font-title: #{$font-title};
    --font-subtext: #{$font-subtext};
    --font-text: #{$font-text};
}

body.cy-web-admin {
    font-family: var(--font-text);
    @import 'main.scss';
    @import 'responsive.scss';
}